import { Examsonline_File, useMy_FilesQuery } from "generated/graphql";
import { useState } from "react";
import {
  Badge,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Container,
  IconButton,
} from "@material-ui/core";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import { FaTrash } from "react-icons/fa";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import ErrorBanner from "component/banners/error";
import Loading from "shared/components/loader/loading";

export default function ImagesPanel(props) {
  const [total, settotal] = useState(0);
  const [limit] = useState(12);

  const { setLargeImage } = useAppState();

  const handleClick = (file: Examsonline_File) => {
    setLargeImage(file);
  };

  const { pages, activePage } = usePagintaion(total, limit);
  const { data, loading, error } = useMy_FilesQuery({
    onCompleted: (res) => {
      settotal(res?.total?.aggregate?.count || 0);
    },
    variables: {
      limit: limit,
      offset: limit * (activePage - 1),
    },
  });

  const files = data?.examsonline_file || [];

  if (error) return <ErrorBanner message="something went wrong" />;

  return (
    <Container>
      {loading && <Loading />}
      <Grid container >{pages()}</Grid>
      <Grid container >
        {files.map((a: any) => {
          return (
            <Grid
              item
              xs={6}
              md={3}
              onClick={() => handleClick(a)}
              className="p-2"
            >
              <Card style={{ height: "100%" }} className="w-100">
                <CardMedia
                  component="img"
                  className="pointer"
                  image={a.info.location}
                  style={{ height: 150, width: "100%", objectFit: "cover" }} // Added style to ensure uniform size
                />
                <CardContent>
                  <Grid container justify="center" alignItems="center">
                    <Grid item>{(a.info.size / 1024).toFixed(2)} MB</Grid>
                    <Grid item>
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => console.log("Primary")}
                      >
                        <FaTrash />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
