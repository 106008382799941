import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Grid,
  Button,
} from "@material-ui/core";

interface TeamMember {
  name: string;
  college: string;
  img?: string;
  description: string;
  github: string;
  linkdIn: string;
}

const TeamMemberCard = (props: TeamMember) => {
  const openLink = (link: string) => {
    window.location.href = link;
  };

  return (
    <Card className="w-100">
      <CardMedia
        component="img"
        height="400"
        image={
          props.img
            ? `${process.env.PUBLIC_URL}/credits/${props.img}.jpeg`
            : "/static/images/cards/contemplative-reptile.jpg"
        }
        alt="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.name}
        </Typography>
        <Typography variant="body2">{props.description}</Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => openLink(props.github)} size="small">
          Github
        </Button>
        <Button onClick={() => openLink(props.linkdIn)} size="small">
          LinkedIn
        </Button>
      </CardActions>
    </Card>
  );
};

export default function Credits() {
  const members: TeamMember[] = [
    {
      name: "Satyam Maurya",
      college: "United College of Engineering",
      img: "satyam",
      description: "FrontEnd Intern",
      github: "https://github.com/ig-rocker",
      linkdIn: "https://www.linkedin.com/in/satyam-maurya-a69a3b195/",
    },
    {
      name: "Siddharth Singh",
      college: "United College of Engineering",
      img: "sid",
      description: "FrontEnd Intern",
      github: "",
      linkdIn: "asdasd",
    },
  ];

  return (
    <Grid container spacing={5} className="w-75 mx-auto p-5 text-center">
      <Grid item className="mx-auto lead" lg={8}>
        <b> Meet our contributors</b>
      </Grid>
      {members.map((a) => {
        return (
          <Grid item xs={12} lg={6}>
            <TeamMemberCard {...a} />
          </Grid>
        );
      })}
    </Grid>
  );
}
