import React, { useState, useCallback } from "react";
import { Container } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
interface TextOptionProps {
  value: string;
  onChange: (value: string) => void;
}

const TextOption: React.FC<TextOptionProps> = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState(() => {
    return value
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
      : EditorState.createEmpty();
  });

  const handleTextChange = useCallback((state: EditorState) => {
    setEditorState(state);
  }, []);

  const handleBlur = useCallback(() => {
    const contentState = editorState.getCurrentContent();
    const rawContent = JSON.stringify(convertToRaw(contentState));
    onChange(rawContent);
  }, [editorState, onChange]);

  return (
    <Container className="bg-info p-2 m-1 border" maxWidth="xl">
      <Editor
        toolbar={{
          options: [
            "inline",
            "list",
            "image",
            "textAlign",
            "blockType",
            "colorPicker",
          ],
          inline: { inDropdown: false },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          blockType: {
            inDropdown: false,
            options: ["Normal", "H1", "H2", "H3", "Code"],
          },
        }}
        editorState={editorState}
        wrapperClassName="option-question-wrapper"
        editorClassName="option-question-editor"
        onEditorStateChange={handleTextChange}
        onBlur={handleBlur}
        placeholder="Please type here to add your answer"
      />
    </Container>
  );
};

export default TextOption;
