import { HTMLProps } from "react";
import { Grid, Chip, useTheme } from "@material-ui/core";

interface Props {
  tags: { tag: string }[];
  variant:
    | "default"
    | "primary"
    | "secondary"
    | "warning"
    | "danger"
    | "info"
    | "dark"
    | "success";
}

export function Tags(props: Props & HTMLProps<any>) {
  const theme = useTheme();

  const colorMap = {
    default: theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
    primary: theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
    secondary: theme.palette.type === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.main,
    warning: theme.palette.type === 'dark' ? theme.palette.warning.dark : theme.palette.warning.main,
    danger: theme.palette.type === 'dark' ? theme.palette.error.dark : theme.palette.error.main,
    info: theme.palette.type === 'dark' ? theme.palette.info.dark : theme.palette.info.main,
    dark: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[400],
    success: theme.palette.type === 'dark' ? theme.palette.success.dark : theme.palette.success.light,
  };

  if (!props.tags) return <p></p>;

  return (
    <Grid container spacing={1} onClick={props.onClick} alignItems="center" className="tags" >
      {props.tags.map((a, index) => (
        <Grid item key={index}>
          <Chip
            label={a.tag}
            size="small"
            style={{
              backgroundColor: colorMap[props.variant] || colorMap.default,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
