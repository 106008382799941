import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useState } from "react";

/**
 *
 * @param total
 * @param limit
 * @returns
 */

const usePagintaion = (total: number | undefined, limit = 10) => {
  const [activePage, setactivePage] = useState<number>(1);

  const reset = () => {
    setactivePage(1);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setactivePage(value);
  };
  const pages = () => {
    return (
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          onChange={handleChange}
          page={activePage}
          count={Math.ceil((total ?? 0) / limit)}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </Grid>
    );
  };

  return { activePage, pages, reset };
};

export default usePagintaion;
