import { useReactiveVar } from "@apollo/client";
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import { useToast } from "shared/hooks/useToast";
import { appState } from "../../cache/appState";
import { useState } from "react";
import axios from "axios";
import "./profile.scss";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import { useGetUserInfo } from "shared/hooks/user/useGetUserInfo";
import { Examsonline_Roles } from "generated/graphql";
import RoleCard from "./role-card";
import ImagesPanel from "shared/files/images-panel";
import FileSizeUsed from "../files/file-size-used";
import BillingTable from "component/billing/billingTable";

export function Profile() {
  const appstate = useReactiveVar(appState);

  let showRandomPassword = (Math.random() + 1).toString(36).substring(5);

  const [password, setPassword] = useState(showRandomPassword);
  const [confirmPassword, setConfirmPassword] = useState(showRandomPassword);
  const { dangerToast, successToast } = useToast();
  const { state } = useAppState();

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const updatePassword = (e: any) => {
    axios
      .put(`/user/`, { email: appstate?.userInfo?.email, password: password })
      .then((res) => {
        successToast("Password successfully changed");
      })
      .catch(() => {
        dangerToast("Error while changing password");
      });
  };

  const { userInfo } = useGetUserInfo();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Container  id="profile" className="my-3">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Paper className="w-100 mb-4">
            <Avatar src={"/images/avatar.png"} style={{ width: "100%", height: "auto" }} />
            <Box p={2}>
              <Typography variant="h6" align="center">
                {state.userInfo?.username}
              </Typography>
            </Box>
          </Paper>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Profile Tabs"
          >
            <Tab label="Personal Info" />
            <Tab label="Change Password" />
            <Tab label="Payment" />
            <Tab label="History" />
            <Tab label="Files" />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={1}>
              <Grid item xs={6} className="text-center m-auto">
                <FileSizeUsed />
              </Grid>
              <Grid item xs={12} className="text-center m-1">
                <Typography variant="h4" className="font-korona">
                  Roles
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {userInfo?.roles?.map((o: Examsonline_Roles) => {
                  return <RoleCard role={o as Examsonline_Roles} />;
                })}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} className="text-center">
                <Paper className="text-center p-4">
                  <Typography variant="h6">Last password changed on 13th Dec</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} className="mt-3">
                <Typography variant="h6">Change Password</Typography>
                <hr />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="New Password"
                  variant="outlined"
                  color="primary"
                  value={password}
                  onChange={handlePassword}
                  type="password"
                  className="\"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  variant="outlined"
                  value={confirmPassword}
                  color="primary"
                  onChange={handleConfirmPassword}
                  type="password"
                  className="\"
                />
              </Grid>
              <Grid item xs={12} lg={4} className="m-auto">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updatePassword}
                  disabled={password !== confirmPassword}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Grid container>
              <BillingTable />
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {/* History content */}
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <Grid container spacing={3} className="p-2 m-2">
              <ImagesPanel />
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
}

function TabPanel(props: { children?: React.ReactNode; value: number; index: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
