import React, { useState, useRef, useLayoutEffect } from "react";
import {
  Examsonline_Tickets,
  useGet_All_Support_TicketsQuery,
} from "generated/graphql";
import ErrorBanner from "component/banners/error";
import { Grid, TextField, Box, CircularProgress, Container } from "@material-ui/core";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import { SupportTicketCard } from "./support-ticket-card";

export default function SupportTicket() {
  const [limit] = useState(5);
  const [search, setsearch] = useState("");
  const [totalcount, settotalcount] = useState<number | undefined>(0);
  const { pages, activePage, reset } = usePagintaion(totalcount || 0, limit);

  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(1);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setWidth(entries[0].contentRect.width);
      }
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  const { data, loading, error } = useGet_All_Support_TicketsQuery({
    variables: {
      limit: limit,
      offset: limit * (activePage - 1),
      _iregex1: search,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      settotalcount(data.total.aggregate?.count);
    },
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsearch(event.target.value);
    reset();
  };

  const isXs = width < 600;

  return (
    <Container ref={containerRef}>
      <Grid container spacing={isXs ? 0 : 2}>
        {!isXs && (
          <Grid item xs={12}>
            <h4>Support Tickets</h4>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            value={search}
            onChange={handleOnChange}
            type="text"
            placeholder="Search by Ticket Detail"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          {loading && <CircularProgress />}
          {error && <ErrorBanner message={error.message} />}
          {data?.examsonline_tickets.map((a: Examsonline_Tickets) => (
            <SupportTicketCard
              key={a.id}
              ticket={a}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          {pages()}
        </Grid>
      </Grid>
    </Container>
  );
}
