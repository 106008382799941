import {
  Typography,
  Grid,
  CardMedia,
  IconButton,
  Button,
  Card,
  Badge,
} from "@material-ui/core";
import {
  Examsonline_Groups,
  useGet_All_Members_Of_GroupLazyQuery,
  useJoin_Contest_ManyMutation,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { FaSignOutAlt, FaUsers } from "react-icons/fa";
import Moment from "react-moment";
import { useHistory } from "react-router";
import { maskEmail } from "shared/hooks/auth/maskEmail";
import { useToast } from "shared/hooks/useToast";
import Formatter from "shared/translations/formatter";

export enum GroupCardColumns {
  "PLAN",
  "OPEN",
}

interface Props {
  group: Examsonline_Groups;
  readonly?: any;
  contest_id?: any;
  isSelectable?: boolean;
  hideColumns?: GroupCardColumns[];
  onSelect?: (test: Examsonline_Groups) => void;
}

export default function GroupCard(props: Props) {
  const { successToast, dangerToast } = useToast();
  const [getallMemebers, { data: member }] =
    useGet_All_Members_Of_GroupLazyQuery({
      variables: {
        limit: 100,
        offset: 0,
        _eq: props.group.id,
      },
    });

  const filteredMember = member?.examsonline_members?.map((m) => {
    return { email: m.email, contest_id: props.contest_id };
  });

  const [joinContestManyMutation] = useJoin_Contest_ManyMutation({
    variables: {
      objects: filteredMember,
    },
  });

  const inviteUsersToContest = async () => {
    await getallMemebers();
    return joinContestManyMutation();
  };

  const assignContest = () => {
    inviteUsersToContest()
      .then((data) => {
        successToast("Invited Group Successfully");
      })
      .catch(() => {
        dangerToast(
          "Something went wrong , unable to Invite Group. Please try again"
        );
      });
  };

  const handleSelect = () => {
    if (props?.onSelect) {
      props.onSelect(props.group);
    }
  };

  const shouldHide = (colName: GroupCardColumns): boolean => {
    if (!props?.hideColumns) return false;

    if (props.hideColumns.includes(colName)) return true;

    return false;
  };
  const { maskedEmail } = maskEmail(props.group.user || "");
  const history = useHistory();
  const gotoGroup = () => {
    history.push(`/group/${props.group.id}`);
  };
  const { t } = useTranslation("common");

  return (
    <Card className="border card-animate  mt-2 w-100">
      <Grid container>
        <Grid item xs={12} lg={2}>
          <CardMedia
            component="img"
            className="m-2 rounded"
            style={{ width: "70%", height: "60px" }}
            image={props.group.files[0]?.info?.location || "/group.svg"}
            title={t("student.dashboardTab.assignmentCard.cardMediaTitle", {}).toString()}
          />
        </Grid>
        <Grid className="m-auto font-krona  text-center" item xs={4} lg={1}>
          <Typography variant="h6"> {props.group.name}</Typography>
        </Grid>
        <Grid className="m-auto text-primary text-center" item xs={4} lg={1}>
          <Typography>
            {props.group?.members_aggregate?.aggregate?.count} <FaUsers />{" "}
          </Typography>
        </Grid>
        <Grid className="m-auto text-primary text-center" item xs={4} lg={2}>
          <Typography>
            <Badge variant="dark">{maskedEmail}</Badge>
          </Typography>
        </Grid>
        <Grid
          className="m-auto text-primary text-center"
          item
          xs={4}
          lg={1}
          hidden={shouldHide(GroupCardColumns.PLAN)}
        >
          <Typography>
            <Badge className="=" p-2 variant="danger">
              1
            </Badge>
          </Typography>
        </Grid>
        <Grid
          className="m-auto text-primary text-center"
          item
          xs={4}
          lg={1}
          hidden={!!props.readonly}
        >
          <Badge variant="secondary" className="p-2">
            <Moment fromNow>{props.group.createdAt}</Moment>
          </Badge>
        </Grid>
        <Grid
          hidden={shouldHide(GroupCardColumns.OPEN) || !!props.readonly}
          className="m-auto text-primary text-center"
          item
          xs={4}
          lg={2}
        >
          <IconButton aria-label="" onClick={gotoGroup}>
            <FaSignOutAlt />
          </IconButton>
        </Grid>
        <Grid hidden={!props.isSelectable} className="m-auto" xs={4} lg={2}>
          <Button
            title="Select"
            onClick={handleSelect}
            color="primary"
            variant="contained"
            size="small"
          >
            Select
          </Button>
        </Grid>
        <Grid
          className="m-auto text-primary text-center"
          hidden={!props.readonly}
          item
          xs={4}
          lg={2}
        >
          <Button color="primary" variant="contained" onClick={assignContest}>
            <Formatter options={{}} text="author.select" />
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
