import { useMutation } from "@apollo/client";
import { useDelete_Topics_By_Test_IdMutation, useUpsert_Topics_For_TestMutation } from "generated/graphql";
import { UPDATE_TEST } from "queries/queries";

export const useUpdateTest = () => {
  const [updateTestMutation, { loading, error }] = useMutation(UPDATE_TEST);

  const [updatetopics] = useUpsert_Topics_For_TestMutation();
  const [ deleteTopics] = useDelete_Topics_By_Test_IdMutation();

  const updateTest = async (test) => {
    try {
      if (test.topics.data.length > 0) {

        await deleteTopics({
          variables:{
            test_id: test.id
          }
        });
        await updatetopics({
          variables: {
            objects: test.topics.data.map((t) => ({
              tag: t.tag,
              test_id: test.id,
            })),
            _eq: test.id,
          },
        });

        delete test.topics;
      }

      const { data } = await updateTestMutation({
        variables: {
          _set: test,
          test_id: test.id,
        },
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  return { updateTest, loading, error };
};
