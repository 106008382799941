import React from "react";
import {
  Button,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Add, Search, PostAdd } from "@material-ui/icons";
import { FaRobot } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    flexWrap: "wrap",
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    textTransform: "none",
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: "1.2rem",
  },
}));

interface EditorActionsProps {
  actions: {
    type: string;
    tooltip: string;
    handler: () => void;
    showText?: boolean;
  }[];
}

const EditorActions: React.FC<EditorActionsProps> = ({ actions }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const icons = {
    add: <Add className={classes.icon} />,
    search: <Search className={classes.icon} />,
    addSection: <PostAdd className={classes.icon} />,
    generate: <FaRobot className={classes.icon} />,
  };

  return (
    <div className={classes.actionsContainer}>
      {actions.map((action, index) => (
        <Tooltip key={index} title={action.tooltip} placement="top" arrow>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={action.handler}
            startIcon={icons[action.type]}
          >
            {!isSmallScreen && action.showText && (
              <Typography variant="button">{action.tooltip}</Typography>
            )}
          </Button>
        </Tooltip>
      ))}
    </div>
  );
};

export default EditorActions;
