// Header.tsx
import React, { ChangeEvent } from "react";
import { Container, Grid, Tab, Tabs } from "@material-ui/core";
import { Timer } from "shared/components/counter/timer";
import { useTestContext } from "context/testProvider";

interface HeaderProps {
  token: any;
  onTimeup: () => void;
}

const HeaderPanel: React.FC<HeaderProps> = ({ token, onTimeup }) => {
  const { activeSection, test, setActiveSection } =
    useTestContext();

  function handleSectionChange(event: ChangeEvent<{}>, value: any): void {
    setActiveSection(value);
  }

  if (!test) return null;
  return (
    <Container maxWidth="xl">
      <Grid
        container
        className="test-header px-md-1 p-md-2 test-header text-center border-bottom font-krona"
      >
        <Grid item xs={12} md={9} className="m-auto">
          <Tabs
            value={activeSection}
            onChange={handleSectionChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            className="text-left"
          
          >
            {test?.test_sections?.map((section: any) => (
              <Tab
                key={section.id}
                label={section.name}
                value={section.id}
                className={activeSection === section.id ? "primary" : ""}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} md={3} className="mb-xs-2 mb-md-0">
          <Timer key={test.id} expiryTime={test.time} onEndTimer={onTimeup} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HeaderPanel;
