import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import HistoryIcon from "@material-ui/icons/History";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useMy_WalletQuery } from "generated/graphql";
import { useGenericModal } from "shared/hooks/modals/useGeneticModal";
import AddBalance from "./addBalance";
import WalletTransactions from "component/payment/walletTransactions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  card: {
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  header: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  addBalanceButton: {},
}));

export default function Wallet() {
  const classes = useStyles();

  const [walletBalance, setwalletBalance] = useState(0);
  const { openGenericModal } = useGenericModal();

  useMy_WalletQuery({
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setwalletBalance(data.examsonline_wallet[0].balance);
    },
  });

  // Mock data
  const chargedAttempts = 15;
  const expiringCredits = 30;
  const expiringDate = "2023-12-31";

  return (
    <Box className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Wallet Overview
      </Typography>
      <Grid container spacing={3}>
        {/* Balance Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.icon}>
                  <AccountBalanceWalletIcon />
                </Avatar>
              }
              title="Current Credits"
              subheader="Available for use"
            />
            <CardContent>
              <div className="d-flex justify-content-between align-items-center">
                <Typography variant="h4">{walletBalance}</Typography>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addBalanceButton}
                  size="small"
                  onClick={() =>
                    openGenericModal({
                      title: "Add Balance",
                      content: <AddBalance />,
                      size: "md",
                    })
                  }
                >
                  Add Balance
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Charged Attempts Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.icon}>
                  <TrendingUpIcon />
                </Avatar>
              }
              title="Charged Attempts"
              subheader="This Month"
            />
            <CardContent>
              <Typography variant="h4">{chargedAttempts}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Expiring Credits Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.icon}>
                  <AttachMoneyIcon />
                </Avatar>
              }
              title="Expiring Credits"
              subheader={"Expires on: " + expiringDate}
            />
            <CardContent>
              <Typography variant="h4">{expiringCredits}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Recent Transactions */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.icon}>
                  <HistoryIcon />
                </Avatar>
              }
              title="Transaction History"
            />
            <CardContent>
              <WalletTransactions />
            </CardContent>
          </Card>
        </Grid>

        {/* Support */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar className={classes.icon}>
                  <HelpOutlineIcon />
                </Avatar>
              }
              title="Need Help?"
              subheader="FAQs and Support"
            />
            <CardContent>
              <Typography>
                For assistance with your wallet, please visit our FAQs or
                contact support.
              </Typography>
              <Button variant="outlined" color="primary">
                Go to Support
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
