import {
  CardContent,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
  Divider,
  Typography,
  Button,
  Badge,
} from "@material-ui/core";
import { Examsonline_Tests } from "generated/graphql";
import { Helper } from "helper/helper";
import { Roles } from "models/roles";
import { RatingReadOnly } from "shared/components/rating/rating-readonly";
import Moment from "react-moment";
import { Tags } from "shared/components/tags/tags";
import { useApolloClient } from "@apollo/client";
import { cloneDeep } from "lodash";
import { GET_TEST_BY_ID } from "queries/queries";
import { useDuplicateTest } from "shared/hooks/tests/useDuplicateTest";
import Loading from "shared/components/loader/loading";
import { useGradient } from "hooks/useGradient";
import NoRecords from "component/banners/no-records";
import { useQuestionsModal } from "shared/hooks/modals/useQuestionModal";

interface Props {
  test: Examsonline_Tests;
}

export default function TestCardSquare({ test }: Props) {
  // const history = useHistory();
  const client = useApolloClient();
  const { duplicateTest, loading: duplicateTestLoading } = useDuplicateTest();
  const role = Helper.getUserRole();
  const openQuestionModal = useQuestionsModal(test);

  const showQuestions = () => {
    openQuestionModal();
  };

  const onCopyTest = async () => {
    const { data } = await client.query({
      query: GET_TEST_BY_ID,
      variables: {
        id: test.id,
      },
    });

    const temp = cloneDeep(data.examsonline_tests[0]);
    delete temp.attempts_aggregate;
    delete temp.id;
    delete temp.createdAt;
    delete temp.files;
    temp.pincode = "1234";

    duplicateTest(temp);
  };

  const { getGradient } = useGradient();

  if (!test) return <NoRecords message="Invalid Test" />;

  return (
    <Card className="group-card-square h-100">
      {duplicateTestLoading && <Loading />}

      <CardActionArea onClick={showQuestions}>
        <CardMedia
          style={{
            height: 0,
            paddingTop: "56.25%", // 16:9 aspect ratio
            backgroundImage: `url('${
              test?.files[0]?.info?.location
            }'), ${getGradient(test.id)}`,
            backgroundSize: "cover",
          }}
        ></CardMedia>

        <CardContent>
          <Typography variant="h6" component="div">
            {test?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {`Author: ${test.author}`}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Badge
              style={{ fontSize: "10px", float: "left" }}
              variant="dot"
            >
              <Moment fromNow>{test.createdAt}</Moment>
            </Badge>
          </Grid>
          <Grid xs={6} className="m-auto text-right">
            <RatingReadOnly max={4} />
          </Grid>
          {role === Roles.AUTHOR && (
            <Grid xs={12} className="mb-2">
              <Divider />
            </Grid>
          )}

          {role === Roles.AUTHOR && (
            <Grid item xs={6}>
              <Button
                size="small"
                variant="outlined"
                className="py-0"
                onClick={onCopyTest}
              >
                Copy & Edit
              </Button>
            </Grid>
          )}

          <div className="d-flex align-items-center">
            <Tags tags={test?.topics} variant={"default"} />
          </div>
        </Grid>
      </CardActions>
    </Card>
  );
}
