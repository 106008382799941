import axios from "axios";
import { useReportTimesSpent } from "component/test/hooks/useReportTimesSpent";
import { Examsonline_Questions, Examsonline_Tests } from "generated/graphql";
import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalContext } from "./modalProvider";
import { Answer } from "models/answer.model";

interface TestContextType {
  activeQuestion: Examsonline_Questions | null;
  setActiveQuestion: (question: Examsonline_Questions | null) => void;
  activeSection: string | null;
  setActiveSection: (section: string | null) => void;
  answers: Answer[];
  addAnswer: (answer: Answer) => void;
  selectQuestionById: (q_id: number) => void;
  handleTabSelect: (section: string) => void;
  test: Examsonline_Tests | undefined;
  setTest: (test: Examsonline_Tests | undefined) => void;
  token: { attempt_id: string; sessionId: string; email: string };
  isInSubmitMode: boolean;
  setIsInSubmitMode: (isInSubmitMode: boolean) => void;
}

const SessionContext = React.createContext<TestContextType | undefined>(
  undefined
);

const TestProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeQuestion, setActiveQuestion] =
    useState<Examsonline_Questions | null>(null);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [test, setTest] = useState<Examsonline_Tests | undefined>();
  const [isInSubmitMode, setIsInSubmitMode] = useState(false);
  const token = useMemo(
    () => jwtDecode(sessionStorage.getItem("sessionToken") || ""),
    []
  );

  useReportTimesSpent(activeQuestion, answers, isInSubmitMode);

  const addAnswer = useCallback(
    (answer: Answer) => {
      setAnswers((prevAnswers) =>
        prevAnswers.map((ans) =>
          ans.questionId === answer?.questionId ? answer : ans
        )
      );
    },
    [setAnswers, answers]
  );

  const selectQuestionById = useCallback(
    (q_id: number) => {
      const question = test?.questions.find((q) => q.id === q_id) || null;
      setActiveQuestion(question);
    },
    [test]
  );

  const handleTabSelect = useCallback(
    (section: string) => {
      setActiveSection(section);
      const firstQuestion = test?.questions.find((q) => q.section === section);
      if (firstQuestion) setActiveQuestion(firstQuestion);
    },
    [test]
  );

  // Initialize answers based on test questions
  useEffect(() => {
    if (test?.questions) {
      const initialAnswers = test.questions.map((question) => ({
        questionId: question.id,
        selectedOptions: [],
        textAnswer: "",
        timeSpent: 0,
        points: 0,
      }));
      setAnswers(initialAnswers);
    }
  }, [test]);

  const contextValue = useMemo(
    () => ({
      activeQuestion,
      setActiveQuestion,
      activeSection,
      setActiveSection,
      answers,
      addAnswer,
      selectQuestionById,
      handleTabSelect,
      test,
      setTest,
      token,
      setIsInSubmitMode,
    }),
    [
      activeQuestion,
      activeSection,
      answers,
      addAnswer,
      selectQuestionById,
      handleTabSelect,
      test,
      token,
    ]
  );

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

export default TestProvider;

export function useTestContext() {
  const context = React.useContext(SessionContext);
  const history = useHistory();
  const { closeAllModals } = useModalContext();


  if (!context) {
    throw new Error("useTestContext must be used within a TestProvider");
  }

  const getActiveQuestionIndex = useCallback(
    () =>
      context.test?.questions.findIndex(
        (question) => question.id === context.activeQuestion?.id
      ),
    [context]
  );

  const isQuestionAnswered = useCallback(
    (questionId: number) => {
      const answer = context.answers.find(
        (ans) => ans.questionId === questionId
      );
      return answer
        ? !!(answer.selectedOptions.length || answer.textAnswer)
        : false;
    },
    [context.answers]
  );

  const submitTest = useCallback(async () => {
    try {
      await axios.post(`session/${context.test?.id}/submit`, {
        attemptId: context.token.attempt_id,
        sessionId: context.token.sessionId,
        studentId: context.token.email,
        answers: context.answers.sort((a, b) => Number(a.questionId) - Number(b.questionId)),
      });

      history.push(`/result/${context.token.attempt_id}`);
      closeAllModals();
    } catch (error) {
      console.error("Error submitting test:", error);
    }
  }, [context, history, closeAllModals]);

  const next = useCallback(() => {
    const currentIndex = getActiveQuestionIndex();
    const nextQuestion = context.test?.questions[currentIndex + 1];
    if (nextQuestion) {
      context.setActiveQuestion(nextQuestion);
      context.setActiveSection(nextQuestion.section);
    } else {
      alert("This is the last question.");
    }
  }, [context, getActiveQuestionIndex]);

  const back = useCallback(() => {
    const currentIndex = getActiveQuestionIndex();
    const previousQuestion = context.test?.questions[currentIndex - 1];
    if (previousQuestion) {
      context.setActiveQuestion(previousQuestion);
    } else {
      alert("This is the first question.");
    }
  }, [context, getActiveQuestionIndex]);

  return {
    ...context,
    getActiveQuestionIndex,
    isQuestionAnswered,
    submitTest,
    next,
    back,
  };
}
