import { useAppContext } from "context/appProvider";

 function useToast(delay = 3000) {

  const appcontext = useAppContext();
  const successToast = (msg: string) => {
    appcontext.showSnackbar(msg, "success");
  };
  const warningToast = (msg: string) => {
    appcontext.showSnackbar(msg, "warning");
  };
  const dangerToast = (msg: string) => {
    appcontext.showSnackbar(msg, "error");
  };
  const infoToast = (msg: string) => {
    appcontext.showSnackbar(msg, "info");
  };

  return { successToast, warningToast, dangerToast, infoToast };
}

export { useToast };