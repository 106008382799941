import "react-toastify/dist/ReactToastify.css";

import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AboutUs from "../pages/about/about";
import { AuthorDashboardNext } from "../pages/author/author";
// import myQuiz from "../component/Dashboard/my-quiz";
import Recharge from "../component/payment/recharge";
import TestDetails from "../component/preview/testDetails";
import { ResultsNext } from "../component/result/result";
import EditTestComponent from "../component/test/components/edit/editTest";
import NextTest from "../component/test/test";
import AppError from "../shared/components/errors/app-error";
import { Profile } from "../shared/components/profile/profile";
import { useGetUserInfo } from "../shared/hooks/user/useGetUserInfo";
import { TestLibrary } from "component/library/test/test-library";
import { ProtectedRoute } from "./protected-route";
import { useClearErrors } from "shared/hooks/utils/useClearErrors";
import AppStoreContainer from "component/library/app-store-container";
import Credits from "component/credits";
import { SideBar } from "component/sidebar/sidebar";

import {
  ConfirmUser,
  AskRegister,
  AcceptInvite,
  Group,
  Student,
  Support,
  OrgAdmin,
  TicketDetailView,
  AskSupport,
  RequestDemoForm,
  ContactUs,
  AuthorLogin,
  StudentAllResults,
  createTestForm,
  Checkout,
  AppHeader,
  Home,
  Preview,
  TermsOfService,
  PrivacyPolicy,
  ForgotPassword,
  ResetPassword,
} from "./lazLoadedComponents";
import ErrorRoutes from "./errorRoutes";
import SessionLogin from "component/preview/SessionLogin";
import SessionDetails from "component/session/sessionDetails";
import Sessions from "component/session/sessions";
import Bill from "pages/bill";
import StudentResultSearch from "component/home/student-search-result";
import { Grid, CircularProgress, Container } from "@material-ui/core";
import SupportTicket from "component/support/support-ticket";
import GoogleAnalyticsTracker from "shared/googleAnalyticsTracker";
import Wallet from "pages/bill/wallet";
import { useAuth } from "context/authProvider";

const renderLoader = () => (
  <div id="loader" className="modal-backdrop show">
    <CircularProgress className="logo" color="primary" />
  </div>
);

export default function AppRouter() {
  useGetUserInfo();

  // clear errors on navigation
  useClearErrors();

  const { isLoggedIn } = useAuth();

  return (
    <Grid item xs={12}>
      <GoogleAnalyticsTracker />
      <Suspense fallback={renderLoader()}>
        <Grid container className="m-2 m-lg-4">
          <Grid item xs={12}>
            <AppHeader />
          </Grid>
        </Grid>
        <Grid container className="m-auto" id="main-content">
          <Grid item md={2} xs={false} hidden={!isLoggedIn}>
            <SideBar />
          </Grid>
          <Grid item xs={12} md={isLoggedIn ? 10 : 12} className=" mx-0 px-0">
            <AppError />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Container >
              <Switch>
                <Route
                  exact
                  path="/confirm/:email/:token"
                  component={ConfirmUser}
                />
                <ProtectedRoute
                  roles={["AUTHOR"]}
                  exact
                  path="/edit/:id"
                  component={EditTestComponent}
                />
                <Route exact path="/login" component={AuthorLogin} />
                <Route path="/signup" component={AskRegister} />
                <ProtectedRoute
                  roles={["SUPPORT"]}
                  exact
                  path="/support"
                  component={Support}
                />
                <Route exact path="/support/ask" component={SupportTicket} />
                <Route
                  path="/accept/:orgid/:email/:code"
                  component={AcceptInvite}
                />
                <ProtectedRoute
                  roles={["AUTHOR"]}
                  exact
                  path="/addtest/:id"
                  component={createTestForm}
                />
                <ProtectedRoute
                  roles={["AUTHOR"]}
                  exact
                  path="/addtest"
                  component={createTestForm}
                />
                <ProtectedRoute
                  roles={["AUTHOR", "STUDENT", "ORG-ADMIN"]}
                  exact
                  path="/wallet"
                  component={Wallet}
                />

                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/result/:attemptId"
                  component={ResultsNext}
                />
                <ProtectedRoute
                  roles={["AUTHOR", "STUDENT"]}
                  exact
                  path="/profile"
                  component={Profile}
                />

                <Route exact path="/preview/:id" component={TestDetails} />
                <Route
                  exact
                  path="/preview/assignment/:id/:asg_id"
                  component={Preview}
                />
                <Route
                  exact
                  path="/session/login/:id"
                  component={SessionLogin}
                />
                <Route exact path="/session/:id" component={SessionDetails} />
                <Route exact path="/sessions/:id" component={Sessions} />
                <Route
                  exact
                  path="/preview/contest/:id/:contest_id"
                  component={Preview}
                />
                <ProtectedRoute
                  roles={["AUTHOR"]}
                  exact
                  path="/author"
                  component={AuthorDashboardNext}
                />
                <Route exact path="/next/test/:id" component={NextTest} />
                <Route
                  exact
                  path="/next/test/:id/:asg_id"
                  component={NextTest}
                />
                <Route
                  exact
                  path="/next/test/contest/:id/:contest_id"
                  component={NextTest}
                />
                <Route exact path="/reset" component={ForgotPassword} />
                <Route exact path="/reset/:token" component={ResetPassword} />
                <Route exact path="/contactus" component={ContactUs} />
                <Route exact path="/upgrade" component={Recharge} />
                <Route exact path="/library" component={TestLibrary} />
                <ProtectedRoute
                  roles={["AUTHOR"]}
                  exact
                  path="/checkout"
                  component={Checkout}
                />
                <Route
                  exact
                  path="/findresults/:email"
                  component={StudentAllResults}
                />
                <Route exact path="/find" component={StudentResultSearch} />
                <Route exact path="/student" component={Student} />
                <Route exact path="/student/group/:id" component={Group} />
                <ProtectedRoute
                  roles={["ORG-ADMIN"]}
                  exact
                  path="/org-admin"
                  component={OrgAdmin}
                />
                <Route exact path="/ticket/:id" component={TicketDetailView} />
                <Route
                  exact
                  path="/docs/privacypolicy"
                  component={PrivacyPolicy}
                />
                <Route
                  exact
                  path="/docs/termsofservice"
                  component={TermsOfService}
                />
                <Route exact path="/about" component={AboutUs} />
                <Route exact path="/contact" component={ContactUs} />
                <ProtectedRoute
                  roles={["AUTHOR"]}
                  exact
                  path="/group/:id"
                  component={Group}
                />
                <Route exact path="/demo" component={RequestDemoForm} />
                <Route path="/demo" component={RequestDemoForm} />
                <Route path="/store" component={AppStoreContainer} />
                <Route path="/credits" component={Credits} />
                <Route path="/billing" component={Bill} />
                <ErrorRoutes />
              </Switch>
            </Container>
          </Grid>
        </Grid>
        <AskSupport />
      </Suspense>
    </Grid>
  );
}
