import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { Theme, createStyles } from "@material-ui/core/styles";

// Define styles for different variants
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: theme.palette.success.light, // Subtle color
      color: theme.palette.success.contrastText,
      borderRadius: "5px", // Square design
      alignItems: "center",
      display: "flex",
      gap: theme.spacing(0.5),
      justifyContent: "center",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    warning: {
      backgroundColor: theme.palette.warning.light, // Subtle color
      color: theme.palette.warning.contrastText,
      borderRadius: "5px", // Square design
      alignItems: "center",
      gap: theme.spacing(0.5),
      display: "flex",
      justifyContent: "center",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    danger: {
      backgroundColor: theme.palette.error.light, // Subtle color
      color: theme.palette.error.contrastText,
      borderRadius: "5px", // Square design
      alignItems: "center",
      gap: theme.spacing(0.5),
      display: "flex",
      justifyContent: "center",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    info: {
      backgroundColor: theme.palette.info.light, // Subtle color
      color: theme.palette.info.contrastText,
      borderRadius: "5px", // Square design
      alignItems: "center",
      gap: theme.spacing(0.5),
      justifyContent: "center",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    default: {
      backgroundColor: theme.palette.grey[400], // Subtle color
      color: theme.palette.grey[50],
      borderRadius: "5px", // Square design
      alignItems: "center",
      gap: theme.spacing(0.5),
      justifyContent: "center",
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    spacing: {
      padding: theme.spacing(0.5), // Adjust the spacing as needed
    },
    small: {
      fontSize: "0.65rem", // Reduced font size
      padding: theme.spacing(0.2), // Reduced padding
    },
    medium: {
      fontSize: "1rem",
      padding: theme.spacing(0.5),
    },
    large: {
      fontSize: "1.25rem",
      padding: theme.spacing(0.75),
    },
  })
);

interface ContextBadgeProps {
  variant: "success" | "warning" | "danger" | "info" | "default"; // Define the variant prop
  children: React.ReactNode;
  className?: string; // Optional className prop
  size?: "small" | "medium" | "large"; // Define the size prop
}

const ContextBadge: React.FC<ContextBadgeProps> = ({
  variant = "default",
  children,
  className,
  size = "medium",
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDarkTheme = theme.palette.type === 'dark';

  const getBackgroundColor = () => {
    switch (variant) {
      case "success":
        return isDarkTheme ? theme.palette.success.dark : theme.palette.success.light; // Subtle color
      case "warning":
        return isDarkTheme ? theme.palette.warning.dark : theme.palette.warning.light; // Subtle color
      case "danger":
        return isDarkTheme ? theme.palette.error.dark : theme.palette.error.light; // Subtle color
      case "info":
        return isDarkTheme ? theme.palette.info.dark : theme.palette.info.light; // Subtle color
      default:
        return isDarkTheme ? theme.palette.grey[600] : theme.palette.grey[400]; // Subtle color
    }
  };

  return (
    <Badge
      className={`${classes[variant]} ${classes.spacing} ${classes[size]} ${className}`}
      style={{ backgroundColor: getBackgroundColor() }}
    >
      {children}
    </Badge>
  );
};

export { ContextBadge };
