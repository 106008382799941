import React, { useState } from "react";
import moment from "moment";

interface DateComponentProps {
  date: string;
  shortDateFormat?: string;
  fullDateFormat?: string;
}

const DateComponent: React.FC<DateComponentProps> = ({ date, shortDateFormat = "LL", fullDateFormat = "MMMM Do YYYY, h:mm:ss a" }) => {
  const [showFullDate, setShowFullDate] = useState(false);

  const handleClick = () => {
    setShowFullDate(!showFullDate);
  };

  const shortDate = moment(date).format(shortDateFormat);
  const fullDate = moment(date).format(fullDateFormat);

  return (
    <div 
      onClick={handleClick} 
      title={showFullDate ? shortDate : fullDate} 
      style={{ cursor: 'pointer' }}
    >
      {showFullDate ? fullDate : shortDate}
    </div>
  );
};

export { DateComponent };
