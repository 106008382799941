import "./edit.scss";
import NoRecords from "../../../banners/no-records";
import QuestionsList from "./questionList";
import Loading from "../../../../shared/components/loader/loading";
import Error from "component/banners/error";
import { Grid } from "@material-ui/core";
import {
  TestEditorProvider,
  useTestEditorContext,
} from "context/testEditorProvider";
import { GridLoadingOverlay } from "@mui/x-data-grid";
import TestInfoCard from "../testInfoCard";
import NextBackButtons from "component/Nav/NextBackButtons";

function EditTest(props: any) {
  const { error, loading, test, data, onBack, onCreate } =
    useTestEditorContext();

  if (error) return <Error />;
  if (loading) return <Loading></Loading>;
  if (!data && !test) return <NoRecords />;

  return (
    <Grid container >
      {loading && <GridLoadingOverlay />}
      <Grid item xs={12} lg={8} className=" p-2 mx-auto  ">
        <TestInfoCard test={test} totalMarks={test?.totalMarks} />
      </Grid>
      <Grid item xs={12} lg={10} className="p-2 mx-auto">
        <QuestionsList />
      </Grid>

      <Grid item xs={12} lg={10} className=" p-2 mx-auto  ">
        <NextBackButtons onBack={onBack} onNext={onCreate} />
      </Grid>
    </Grid>
  );
}
export default () => (
  <TestEditorProvider>
    <EditTest />
  </TestEditorProvider>
);
