import React, { useMemo, useState, useCallback } from "react";
import { Examsonline_Questions } from "generated/graphql";
import { Helper } from "helper/helper";
import {
  Box,
  Button,
  Collapse,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Delete, FileCopy } from "@material-ui/icons";
import Question, { QuestionMode } from "component/question/question";
import { useTestEditorContext } from "context/testEditorProvider";
import MarksInput from "component/marksInput";
import Section from "../section";
import "./questionList.css";
import { useTheme } from "@material-ui/core/styles";

const QuestionsList = () => {
  const {
    test,
    onCopy,
    setActiveQuestion,
    question: activeQuestion,
    handleDelete,
    uploadImageCallBack,
    updateTestSection,
  } = useTestEditorContext();

  const theme = useTheme();

  // State to track the currently open question
  const [openQuestionId, setOpenQuestionId] = useState<number | null>(null);

  // Memoize sorted sections
  const sortedSections = useMemo(() => {
    return test?.test_sections
      ? [...test.test_sections].sort((a, b) => {
          if (!a.createdAt || !b.createdAt) return 0;
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        })
      : [];
  }, [test.test_sections]);

  // Memoize filtered questions by section
  const getQuestionsBySection = useCallback(
    (sectionId: string) =>
      Helper.filterQuesBySection(test.questions, sectionId),
    [test.questions]
  );

  // Handler for selecting a question
  const handleItemClick = useCallback(
    (question: Examsonline_Questions) => {
      setActiveQuestion(question);
      setOpenQuestionId((prevId) =>
        prevId === question.id ? null : question.id
      );
    },
    [setActiveQuestion]
  );

  // Render individual questions
  const QuestionItem = React.memo(
    ({
      question,
      index,
    }: {
      question: Examsonline_Questions;
      index: number;
    }) => {
      const isOpen = question.id === openQuestionId;

      return (
        <div key={question.id}>
          <ListItem
            button
            onClick={() => handleItemClick(question)}
            selected={question.id === activeQuestion?.id}
            className={
              !Helper.validaeQuestion(question).isValid
                ? "bg-warning"
                : "border-bottom"
            }
          >
            <Grid container spacing={2} >
              <Grid item xs={12} md={8} lg={9}>
                <Typography
                  variant="inherit"
                  className="text-overflow-ellipsis"
                  style={{ color: theme.palette.text.primary }}
                >
                  Q. {index + 1} {Helper.getQUestionText(question)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={3}
                md={4}
                className="d-flex justify-content-between"
              >
                <span className="float-right">
                  <MarksInput
                    questionId={question.id}
                    key={question.id}
                    initialMarks={question.marks}
                  />
                </span>
                <Button
                  size="small"
                  title="Copy Question"
                  onClick={onCopy}
                  variant="text"
                >
                  <FileCopy />
                </Button>
                <Button
                  size="small"
                  title="Delete Question"
                  onClick={() => handleDelete(question.id)}
                  variant="text"
                  color="secondary"
                >
                  <Delete />
                </Button>
              </Grid>
            </Grid>
          </ListItem>
          {isOpen && (
            <Collapse in={isOpen}>
              <div className="p-2">
                <Question
                  question={question}
                  mode={QuestionMode.edit}
                  uploadImageCallBack={uploadImageCallBack}
                />
              </div>
            </Collapse>
          )}
        </div>
      );
    }
  );

  return (
    <Grid item xs={12} className="m-0 px-1 px-lg-4">
      {sortedSections.map((section) => (
        <List style={{ width: "100%" }} key={section.id}>
          <Section
            handleChange={updateTestSection}
            section={section}
            key={section.id}
          >
            {getQuestionsBySection(section.id).map((question, index) => (
              <QuestionItem
                key={question.id}
                question={question}
                index={index}
              />
            ))}
          </Section>
        </List>
      ))}
    </Grid>
  );
};

export default QuestionsList;
