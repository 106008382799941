import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";

import { Option } from "../../../../models/option.model";
import SelectOption from "./selectOption";
import TextOption from "./textOption";
import { Examsonline_Questions } from "generated/graphql";
import "./options.scss";
import { Answer } from "models/answer.model";
import { QuestionType } from "component/enums/question.type.enum";

interface Props {
  question: Examsonline_Questions;
  answer: Answer | undefined;
  onAnswerChange: (answer: Answer) => void;
}

const Options: React.FC<Props> = (props) => {
  console.log(props);

  const { question } = props;
  const options = question.options as Option[];
  const [answer, setAnswer] = useState<Answer>(props.answer);

  const isSelected = (option: Option) => {
    return (answer?.selectedOptions ?? []).some((op) => op === option.id);
  };

  const handleOptionClick = (option: Option) => {
    let selectedOptions = [...(answer?.selectedOptions ?? [])];
    if (isSelected(option)) {
      selectedOptions = selectedOptions.filter((op) => op !== option.id);
    } else {
      selectedOptions.push(option.id);
    }
    setAnswer({ ...answer, selectedOptions });
  };

  const handleTextAnswerChange = (textAnswer: string) => {
    setAnswer((answer) => ({ ...answer, textAnswer, selectedOptions: [] }));
  };

  useEffect(() => {
    props.onAnswerChange(answer);
  }, [answer]);

  useEffect(() => {
    setAnswer(props.answer);
  }, [props.answer]);


  return (
    <Grid container className="options">
      {options.map((option: Option, index: number) => (
        <SelectOption
          key={option.id}
          option={option}
          index={index}
          isSelected={isSelected(option)}
          onOptionClick={handleOptionClick}
        />
      ))}

      <Grid container item hidden={question.type !== QuestionType.TEXT}>
        <Grid item xs={12} lg={8} md={9} className="text-center m-2 mx-auto  ">
          <TextOption
            key={question.id}
            value={answer?.textAnswer}
            onChange={handleTextAnswerChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Options;
