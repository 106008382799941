import React from "react";
import { Formik } from "formik";
import { useStudentLogin } from "hooks/useStudentLogin";
import { TextField, Button, Grid } from "@material-ui/core";
import * as yup from "yup";

interface Props {
  initialValues: {
    email?: string;
    sessionId?: string;
  };
  disabled?: boolean;
}

const schema = yup.object({
  email: yup
    .string()
    .required("No email or Roll No provided.")
    .min(3)
    .matches(/^[a-zA-Z0-9_.@-]+$/, "Invalid Email or Roll No"),
  sessionId: yup
    .string()
    .required("No sessionId provided.")
    .min(4, "sessionId should be 4 characters"),
});

const StudentLoginForm: React.FC<Props> = ({ initialValues, disabled }) => {
  const { login } = useStudentLogin();

  const handleSubmit = (form: any) => {
    login({ ...form, sessionId: form.sessionId });
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Enter your email or Roll no"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="sessionId"
                name="sessionId"
                value={values.sessionId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.sessionId && !!errors.sessionId}
                helperText={touched.sessionId && errors.sessionId}
                inputProps={{ maxLength: 4 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={disabled || !isValid}
                type="submit"
                variant="contained"
                color="primary"
                className="mt-1 mb-4"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default StudentLoginForm;
