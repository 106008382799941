import { ContextBadge } from "component/contextBadge";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import Formatter from "shared/translations/formatter";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(4),
    boxSizing: "border-box",
  },
  copyright: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  version: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: theme.spacing(1),
    },
  },
}));

export function Footer() {
  const { state } = useAppState();
  const classes = useStyles();

  return (
    <Grid container hidden={!state.showFooter} className={classes.footer}>
      <Grid container alignItems="center" justifyContent="space-around">
        <Grid item xs={12} sm={6} className={classes.copyright}>
          <Typography variant="body2" color="textSecondary">
            &copy; <Formatter options={{}} text={"footer1.copyright"} /> {" "}
            <strong>ExamsCreator</strong>. <Formatter options={{}} text={"footer1.rightReserved"} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.version}>
          <Typography variant="body2" color="textSecondary">
            <Formatter options={{}} text={"footer1.version"} /> : {" "}
            <ContextBadge variant="info">
              {import.meta.env.VITE_REACT_APP_VERSION}
            </ContextBadge>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
