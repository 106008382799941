import { initialiseTestValue } from 'component/forms/create-test-form';
import { TestForm } from 'component/forms/testForm';
import { useModalContext } from 'context/modalProvider';
import { useState } from 'react';
import { useCreateTest } from './useCreateTest';
import { useHistory } from 'react-router-dom';
import { clone, cloneDeep, omit } from 'lodash';
import jwt_decode from "jwt-decode";


export const useCreateTestModal = () => {
  const { openModal, closeModal } = useModalContext();
  const [test,] = useState(initialiseTestValue);
  const history = useHistory();

  const { createTest} = useCreateTest();

  const handleSubmit = async (test) => {

    let author: any = localStorage.getItem("token");
    if (!!author) {
      author = jwt_decode(author);
    }

    const temp = cloneDeep(test);
    delete temp.questions;

    temp.test_sections ={ data:   [{ name: "default" }]};
    temp.topics = { data: temp.topics.map((t: any) => (!!t?.tag ? t : { tag: t })).map((t: any) => omit(t, "__typename"))};
    temp.author = author.username;

   const data  =  await createTest(temp);
   
   closeModal();

   history.push(`/edit/${data.insert_examsonline_tests.returning[0].id}`);
  }


  const openCreateTestModal = () => {
    const title = 'Create Test';
    const content = (
      // Here you can render your TestForm component or any other content you want for the modal
      <TestForm handleSubmit={handleSubmit} onBack={undefined} test={test} nextbtnRef={undefined} />
    );
    const footer = null; // You can customize the footer if needed
    const size = 'lg'; // You can adjust the size of the modal as needed

    openModal(title, content, footer, size);
  };

  const closeCreateTestModal = () => {
    closeModal(); // This will close the top modal in the stack
  };

  return { openCreateTestModal, closeCreateTestModal };
};

