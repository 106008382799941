import { Chip, Grid } from "@material-ui/core";
import {
    useGetWalletTransactionsQuery,
  } from "generated/graphql";
  import { useTable } from "hooks/useTable";
  
  export default function WalletTransactions() {
    const { data, fetchMore } = useGetWalletTransactionsQuery({
      variables: {
        limit: 10,
        offset: 0,
      },
      notifyOnNetworkStatusChange: true,
    });
  
    const tableData = data?.examsonline_wallet_transactions.map((transaction) =>{

      return {...transaction, type: transaction.type === "ADD" ? <Chip label="Credit" color="primary" /> : <Chip label="Debit" color="secondary" />}
    }) || [];
    const total = data?.total.aggregate?.count || 0;

  
    const table = useTable({
      data: tableData,
      filterColumns: ["id", "__typename", "plan", "orgId"],
      dateFormat: "MMM D YYYY",
      limit: 10,
      noRecordsMessage: "No billing records found",
      pagination: true,
      total: total,
      onPageChange(page, limit) {
        fetchMore({
          variables: {
            offset: page * limit,
            limit,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;
  
            const existingIds = new Set(prevResult.examsonline_wallet_transactions.map((t: any) => t.transaction_id));
            const newTransactions = fetchMoreResult.examsonline_wallet_transactions.filter((t: any) => !existingIds.has(t.transaction_id));
  
            return {
              ...prevResult,
              examsonline_wallet_transactions: [
                ...prevResult.examsonline_wallet_transactions,
                ...newTransactions,
              ],
              total: fetchMoreResult.total,
            };
          },
        });
      }
    });
  
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {table}
        </Grid>
      </Grid>
    );
  }
