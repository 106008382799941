import { Typography, LinearProgress, Box } from "@material-ui/core";
import { useTotal_File_Size_UsedQuery } from "generated/graphql";

export default function FileSizeUsed() {
  const { data } = useTotal_File_Size_UsedQuery();
  const totalFileSizeUsedMb =
    (data?.examsonline_file_aggregate.aggregate?.sum?.size || 0) / 1000 || 0;
  const maxFileSizeMb = 100 * 1000; // 100 GB in MB
  let progressValue = (totalFileSizeUsedMb / maxFileSizeMb) * 100;

  if (progressValue > 100) {
    progressValue = 100;
  }


  return (
    <Box>
      <Typography variant="h6" className="font-korona">
        Files
      </Typography>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            variant="determinate"
            value={progressValue}
          />
        </Box>
        <Box minWidth={10}>
          <Typography variant="body2" color="textSecondary">{`${totalFileSizeUsedMb} MB used`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
