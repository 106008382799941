import "./init";

import "./index.css";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import common_en from "./translations/en.json";
import common_hindi from "./translations/hindi.json";

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
// import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { axiosDefaults } from "./configs/axios/default";
import * as serviceWorker from "./serviceWorker";
import apolloLogger from "apollo-link-logger";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { AuthLink } from "./shared/hooks/apollo/authLink";
import { webSocketLink } from "./shared/hooks/apollo/webSocketLink";
import { LogoutLink } from "./shared/hooks/apollo/logoutLink";
import ProPlanWrapper from "component/pro-plan/proPlanWrapper";
import { ModalProvider } from "context/modalProvider";
import { AppContextProvider } from "context/appProvider";

const { wsLink } = webSocketLink();
const { logoutLink } = LogoutLink();
/** retry strategy */
// const retrylink = new RetryLink();

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: any, value: any) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: `${import.meta.env.VITE_REACT_APP_GRAPH_URL}`,
});

// split links
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

// auth link
const { authLink } = AuthLink();
// configure apollo client
const createApolloClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([
      apolloLogger,
      logoutLink,
      authLink,
      cleanTypeName,
      splitLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        // Type policy map
        Query: {
          fields: {
            examsonline_assignments: {
              keyArgs: false,
              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });
};

/** setup apollo client */
const client = createApolloClient();

/** setup translations */
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    hindi: {
      common: common_hindi,
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <I18nextProvider i18n={i18next}>
      <React.StrictMode>
        {axiosDefaults()}
        <AppContextProvider>
          <ProPlanWrapper isProUser={undefined}>
            <ModalProvider>
              <App />
            </ModalProvider>
          </ProPlanWrapper>
        </AppContextProvider>
      </React.StrictMode>
    </I18nextProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
