import {
    Grid,
    CardContent,
    Typography,
    makeStyles,
    Card
  } from "@material-ui/core";
  import {
    Timeline as TimelineIcon,
    QueryBuilder as QueryBuilderIcon,
    Group as GroupIcon,
    CheckCircle as CheckCircleIcon,
    Stars as StarsIcon,
    TrendingUp as TrendingUpIcon,
    TrendingDown as TrendingDownIcon,
    Functions as FunctionsIcon,
    Speed as SpeedIcon,
  } from "@material-ui/icons";
  import { useTestById } from "shared/hooks/useTestById";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      marginBottom: theme.spacing(3),
    },
    card: {
      marginBottom: theme.spacing(2),
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    iconContainer: {
      flex: "0 0 60px", // Fixed width for the icon container
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      fontSize: "3rem", // Increase icon size
    },
    contentContainer: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    title: {
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    number: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: theme.spacing(0.5),
    },
    description: {
      fontSize: "0.9rem",
      color: theme.palette.text.secondary,
    },
  }));

  const getCategoryColor = (averageScore) => {
    if (averageScore >= 80) return "green";
    if (averageScore >= 60) return "yellow";
    return "red";
  };
  
  const TestMetadata = ({ testId }) => {
    const classes = useStyles();
  
    const { data } = useTestById(testId);
    const test = data?.examsonline_tests[0];
    const metadata = test?.metadata;
  
    if (!metadata) return null;

    const categoryColor = getCategoryColor((metadata.averageScore / test?.points) * 100);
  
    const conclusions = [
      "The test has been attempted by a significant number of students, showing its popularity.",
      "The average score indicates the difficulty level of the test is balanced.",
      "A high completion rate reflects that most students are able to finish the test.",
      "The spread between the highest and lowest scores suggests room for improvement in preparation.",
    ];
  
    return (
      <Grid container spacing={3}>
        {[
          {
            icon: <TimelineIcon color="primary" className={classes.icon} />,
            label: "Total Attempts",
            value: metadata.totalAttempts,
            description: "Total number of attempts made by all students.",
          },
          {
            icon: <QueryBuilderIcon color="secondary" className={classes.icon} />,
            label: "Total Sessions",
            value: metadata.totalSessions,
            description: "Total test sessions conducted.",
          },
          {
            icon: <GroupIcon color="action" className={classes.icon} />,
            label: "Total Students",
            value: metadata.totalStudents,
            description: "Total unique students who participated.",
          },
          {
            icon: <CheckCircleIcon color="primary" className={classes.icon} />,
            label: "Completion Rate",
            value: `${metadata.completionRate}%`,
            description: "Percentage of students who completed the test.",
          },
          {
            icon: <StarsIcon color="secondary" className={classes.icon} />,
            label: "Average Score",
            value: metadata.averageScore?.toFixed(2),
            description: "The average score achieved across all attempts.",
          },
          {
            icon: <TrendingUpIcon color="primary" className={classes.icon} />,
            label: "Highest Score",
            value: metadata.highestScore,
            description: "The highest score achieved in the test.",
          },
          {
            icon: <TrendingDownIcon color="secondary" className={classes.icon} />,
            label: "Lowest Score",
            value: metadata.lowestScore,
            description: "The lowest score recorded.",
          },
          {
            icon: <FunctionsIcon color="action" className={classes.icon} />,
            label: "Median Score",
            value: metadata.medianScore?.toFixed(2) || "N/A",
            description: "The median score, showing the middle value.",
          },
          {
            icon: <SpeedIcon color="primary" className={classes.icon} />,
            label: "Average Time",
            value: `${metadata.averageTime?.toFixed(2) || "N/A"} mins`,
            description: "Average time spent on the test.",
          },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Card className={classes.card}>
              <div className={classes.iconContainer}>{item.icon}</div>
              <CardContent className={classes.contentContainer}>
                <Typography className={classes.number}>{item.value}</Typography>
                <Typography variant="h6" component="p">
                  {item.label}
                </Typography>
                <Typography className={classes.description}>
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
  
        <Grid item xs={12}>
          <Card className={classes.card} style={{ borderLeft: `5px solid ${categoryColor}` }}>
            <CardContent>
              <Typography variant="h5" component="h2" className={classes.title}>
                Insights & Conclusions
              </Typography>
              <Typography
                variant="body2"
                component="ul"
                className={classes.description}
              >
                {conclusions.map((conclusion, index) => (
                  <li key={index}>{conclusion}</li>
                ))}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };
  
  export { TestMetadata };
