import { Paper, Grid, Button, Badge } from "@material-ui/core";
import { DateComponent } from "component/date";
import { Examsonline_Tickets } from "generated/graphql";
import { useHistory } from "react-router";
interface Props {
  ticket: Examsonline_Tickets;
}

export function SupportTicketCard(props: Props) {
  const history = useHistory();

  const viewTicket = () => {
    history.push(`/ticket/${props.ticket.id}`);
  };

  const getButtonVariant = (status: string) => {
    if (status === "OPEN") {
      return "contained";
    } else if (status === "IN-PROGRESS") {
      return "outlined";
    } else {
      return "text";
    }
  };

  const a = props.ticket;

  return (
    <Paper style={{ fontSize: "1.4em" }} className="p-1 my-2">
      <Grid container spacing={2}>
        <Grid item lg={2} className="font-krona">
          {a.name}
        </Grid>
        <Grid item xs={12} lg={4} className="text-primary p-2 text-left">
          {a.description}
        </Grid>
        <Grid item>
          <Badge className="mx-2" color="primary">
            Assigned
          </Badge>

          <br />
          {a.assigned}
        </Grid>
        <Grid item>
          <Badge className="mx-2" color="primary">
            Created Date
          </Badge>

          <br />
          <DateComponent date={a.createdAt} />
        </Grid>
        <Grid item>
          <Badge className="mx-2" color="primary">
            Updated Date
          </Badge>

          <br />
          <DateComponent date={a.updatedAt} />
        </Grid>
        <Grid item className="m-auto" lg={2}>
          <Button
            onClick={viewTicket}
            className="m-auto"
            size="small"
            variant={getButtonVariant(a.status)}
          >
            {a.status}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
