import { ButtonGroup, Button, Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import {
  Examsonline_Questions,
  Examsonline_Session,
  Examsonline_Tests,
  useGet_Test_SessionsQuery,
} from "generated/graphql";
import { useTable } from "hooks/useTable";
import { useParams, useHistory } from "react-router-dom";
import { useShareTest } from "hooks/useShare";
import { useCreateSession } from "hooks/useCreateSession";
import { List, Share } from "@material-ui/icons";
import moment from "moment";
import useSessionStatus from "hooks/useSessionStatus";
import QuestionAccordion from "shared/components/questions-accordian/question-accordian";
import { useGenericModal } from "shared/hooks/modals/useGeneticModal";
import { SessionAPI } from "api/sessionAPI";
import { DateComponent } from "component/date";

interface Props {
  limit?: number;
  testId?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default function SessionGrid(props: Props) {
  const classes = useStyles();
  const [limit] = useState(props.limit || 10);

  const params: { id: string | undefined } = useParams();
  const history = useHistory();

  const handleOnChange = (e: any) => {
    refetch({ _iregex: e });
  };
  const { data, loading, fetchMore, refetch } = useGet_Test_SessionsQuery({
    variables: {
      _eq: props?.testId || parseInt(params.id || ""),
      limit: 1000,
      offset: 0,
    },
  });


  const getQuestionModalContent = async (id: string) => {
    const api = new SessionAPI();
    const data = (await api.getSessionById(id)) as Examsonline_Session & {
      metadata: { totalAttempts: number };
      test: Examsonline_Tests;
    };
    const questions: Examsonline_Questions[] = data?.test?.questions || [];

    return (
      <QuestionAccordion
        questions={questions || []}
        sections={data.test?.test_sections || []}
      />
    );
  };

  const { handleOpen } = useShareTest();
  const { ModalWithForm } = useCreateSession(
    props?.testId || parseInt(params.id || "")
  );

  const { openGenericModal } = useGenericModal();

  const tableData =
    data?.examsonline_session?.map(
      (
        session: Examsonline_Session & {
          metadata: { totalAttempts: number };
          test: Examsonline_Tests;
        }
      ) => {
        const status = useSessionStatus(session);

      return {
          id: session.id,
          name: (
            <Button
              variant="text"
              color="primary"
              onClick={() => history.push(`/session/${session.id}`)}
            >
              {session.name}
            </Button>
          ),
          createdAt: <DateComponent date={session.createdAt} />,
          status: status().label,
          startTime: <DateComponent date={session.startTime} />,
          endTime:  <DateComponent date={session.endTime} />,
          duration: moment
            .duration(moment(session.endTime).diff(moment(session.startTime)))
            .humanize(),
          attempts: session.metadata?.totalAttempts,
          actions: (
            <ButtonGroup variant="text" size="small">
              <Button
                onClick={() =>
                  handleOpen(
                    `${import.meta.env.VITE_APP_URL}/session/login/${
                      session?.id
                    }`
                  )
                }
                startIcon={<Share />}
              >
                Share
              </Button>
            </ButtonGroup>
          ),
          Questions: (
            <Button
              variant="text"
              onClick={async () => {
                const content = await getQuestionModalContent(session.id);
                openGenericModal({
                  title: `Questions for ${session.name} on date ${moment(
                    session.startTime
                  ).format("LLL")}`,
                  content,
                  size: "md",
                });
              }}
            >
              <List /> Questions
            </Button>
          ),
        };
      }
    ) || [];

  const getRowClassName = (session: any) => {
    switch (session.status) {
      case "active":
        return "row-active";
      case "completed":
        return "row-completed";
      default:
        return "";
    }
  };

  const table = useTable({
    data: tableData,
    heading: <ModalWithForm />,
    filterColumns: ["id", "__typename", "created_at"],
    dateFormat: "MMM D YYYY",
    limit: limit,
    noRecordsMessage: "No test sessions found",
    pagination: true,
    total: data?.total.aggregate?.count || 0,
    toolbar: true,
    handleSearch: handleOnChange,
    onPageChange: (page, limit) => {
      fetchMore({
        variables: {
          offset: page * limit,
          limit: limit,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;

          const existingIds = new Set(
            prevResult.examsonline_session.map((t: any) => t.id)
          );
          const newSessions = fetchMoreResult.examsonline_session.filter(
            (t: any) => !existingIds.has(t.id)
          );

          return {
            ...prevResult,
            examsonline_session: [
              ...prevResult.examsonline_session,
              ...newSessions,
            ],
            total: fetchMoreResult.total,
          };
        },
      });
    },
    rowClassName: getRowClassName, // added rowClassName here
  });
  return (
    <Grid container className={classes.root}>
      {loading && <div>Loading...</div>}

      {table}
    </Grid>
  );
}