import { lazy } from "react";

// Lazy Loading
export const ConfirmUser = lazy(
  () => import("component/auth/confirm/confirm-user")
);
export const AskRegister = lazy(
  () => import("component/auth/registration/ask-registration")
);
export const AcceptInvite = lazy(
  () => import("component/organisation/accept/accept-invite")
);
export const Group = lazy(() => import("component/groups/group"));
export const Student = lazy(() => import("../component/student/student"));
export const Support = lazy(() => import("component/support/support"));
export const OrgAdmin = lazy(() => import("component/org-admin/org-admin"));
export const TicketDetailView = lazy(
  () => import("component/support/ticket/ticket-detail-view")
);
export const AskSupport = lazy(() => import("component/support/ask-support"));
export const RequestDemoForm = lazy(
  () => import("component/forms/request-demo-form")
);
export const ContactUs = lazy(
  () => import("../component/contact-us/contact-us")
);
export const Http404 = lazy(() => import("../shared/components/errors/404"));
export const AuthorLogin = lazy(
  () => import("../component/auth/login/common-login")
);
export const StudentAllResults = lazy(
  () => import("../component/result/student-all-results")
);
export const createTestForm = lazy(
  () => import("../component/forms/create-test-form")
);
export const Checkout = lazy(() => import("../component/payment/checkout"));
export const AppHeader = lazy(() => import("../component/header/app-header"));
export const Home = lazy(() => import("../component/home/home"));
export const Preview = lazy(() => import("../component/preview/testDetails"));
export const ResetPassword = lazy(
  () => import("../component/home/reset-password")
);
export const ForgotPassword = lazy(
  () => import("../component/home/forgot-password")
);

export const TermsOfService = lazy(() => import("../docs/terms-of-service"));

export const PrivacyPolicy = lazy(() => import("../docs/privacy-policy"));
