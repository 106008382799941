import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

interface UseFetchOptions {
  skip?: boolean;
  token?: string;
  onCompleted?: (data: any) => void;
}

const useFetch = <T,>(path: string, options: UseFetchOptions = {}) => {
  const { skip = false, token, onCompleted } = options;
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  const fetchData = useCallback(async () => {
    if (skip) return;
    setLoading(true);
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
    try {
      const response = await axios.get(path, {
        headers
      });
      setData(response.data);
      setError(null);
      if (onCompleted) {
        onCompleted(response.data);
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [path, skip, token, onCompleted]);

  useEffect(() => {
    fetchData();
  }, [fetchData, skip]); // Added skip as a dependency

  return { data, loading, error, refetch: fetchData };
};

export default useFetch;