// import "react-data-grid/dist/react-data-grid.css";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "bootstrapHelper.scss";
// import GA from "./helper/google.analytics";
import AppRouter from "./routes/app-router";
// import ImageViewLarge from "./shared/files/view-large/image-view-large";
import "./theme/sbadmin.scss";
import "./theme/theme.scss";
import { Footer } from "shared/components/footer/footer";
import { AuthProvider } from "context/authProvider";
import ProPlanWrapper from "component/pro-plan/proPlanWrapper";
import { useNetworkStatus } from "hooks/useNetworkStatus";
import { init } from "helper/google.analytics";
import { ThemeContextProvider } from "context/ThemeContext";
import { createTheme, Grid, ThemeProvider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function App() {
  const apiUrl = import.meta.env.VITE_REACT_APP_API_URL || "";

  const { isOnline } = useNetworkStatus(apiUrl);
  console.log("isOnline", isOnline);
  const trackingId = import.meta.env.VITE_REACT_APP_GA_TRACKING_ID || "";
  init(trackingId);

  const theme = createTheme({
    palette: {
      type: "light",
      background: {
        default: "#ffffff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={0}>
        {!isOnline && (
          <Alert severity="error">
            You are offline. Please check your internet connection.
          </Alert>
        )}
        <BrowserRouter>
          <AuthProvider>
            <ThemeContextProvider>
              <Helmet>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <title>ExamsCreator</title>
                <link rel="canonical" href="http://examsonline.asia" />
                <meta
                  name="description"
                  content="create and conduct online exams and share with students.
      Get detailed results and test analytics.Sign up for free and manage your exams online today"
                />
              </Helmet>
              {/* {GA.init() && <GA.RouteTracker />} */}
              <ProPlanWrapper isProUser={undefined}>
                <AppRouter></AppRouter>
              </ProPlanWrapper>
              <Footer />
            </ThemeContextProvider>
          </AuthProvider>
        </BrowserRouter>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
