import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Helper } from "helper/helper";
import Axios from "axios";
import { useToast } from "shared/hooks/useToast";

interface LoginParams {
  email: string;
  sessionId: string;
  testid: number;
  asg_id: string;
  contest_id?: string;
  onloginSuccess?: any;
}

interface LoginResult {
  hasError: boolean;
  login: (params: LoginParams) => void;
  token: string | null;
}

export function useStudentLogin(): LoginResult {
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const { successToast, warningToast } = useToast();

  const login = (params: LoginParams) => {
    // event start test
    Helper.sendGAEvent("STUDENT", "start test");

    setHasError(false);
    Axios.post("/auth/session/login", {
      ...params,
    })
      .then((response) => {
        if (response.status === 201) {
          console.log("Login successfull");
          const token = response.data["sessionToken"];
          window.sessionStorage.setItem("sessionToken", token);
          sessionStorage.setItem("T_I_P", params.sessionId.toString());
          successToast("Access Code verified, Now you can start the test!");

          history.push(`/next/test/${params.sessionId}`);

          params.onloginSuccess?.();
        }
      })
      .catch((error) => {
        setHasError(true);
        warningToast(`${error.response.data.message}`);
      });
  };


  return {
    hasError,
    login,
    token: sessionStorage.getItem("sessionToken")
  };
}
