import { useAuth } from "context/authProvider";
import { Route, useHistory, useLocation } from "react-router-dom";
import { AuthorLogin } from "./lazLoadedComponents";
import { Grid, Modal, Backdrop, Fade, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const ProtectedRoute = (props) => {
  const classes = useStyles();

  const currentRole = localStorage.getItem("role");
  const history = useHistory();
  const location = useLocation();

  const { isLoggedIn } = useAuth();

  
  const handleClose = () => {
    history.push("/");
  };

  const isValid =
    props.roles.includes(currentRole) &&
    isLoggedIn &&
    location.pathname === props.path;
  const downprops: any = props;

  if (!isValid && location.pathname === props.path) {
    console.log("isnot Valid", currentRole);
  }

  return (
    <>
      <Modal
        open={!isValid && location.pathname === props.path}
        onClose={handleClose}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!isValid}>
          <div className={classes.paper}>
            <Grid container>
              <AuthorLogin />
            </Grid>
          </div>
        </Fade>
      </Modal>
      <Route {...downprops} />
    </>
  );
};
