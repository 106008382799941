import "./cards.scss";
import NoRecords from "../../../component/banners/no-records";
import { TestCardNext, TestCardColumns } from "./test-card/testCard";
import { Examsonline_Tests } from "generated/graphql";
import { Container } from "@material-ui/core";

interface Props {
  tableData: any[]; // table data
  endpoint?: string;
  ispagination?: boolean;
  callback?: [Function]; // each index represents each column
  hideColumns?: TestCardColumns[];
  isSelectable?: boolean;
  onSelect?: (test: Examsonline_Tests) => void;
}

export default function TestCards(props: Props) {
  if (!!props && props?.tableData?.length === 0)
    return (
      <Container>
        <NoRecords />
      </Container>
    );
  return (
    <>
      {props &&
        props?.tableData?.map((b) => {
          return (
            <TestCardNext
              key={b.id}
              isSelectable={props.isSelectable}
              hideColumns={props?.hideColumns}
              edit={undefined}
              test={b}
              onSelect={props?.onSelect}
            />
          );
        })}
    </>
  );
}
