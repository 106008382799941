import { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TablePagination,
  TableSortLabel,
  TextField,
  Grid,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import NoRecords from "component/banners/no-records";
import moment from "moment";
import { ReactElement } from "react";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    "MuiTableCell-root": {
      fontSize: ".8rem",
      fontWeight: "bold",
      margin: "0",
      padding: "3px",
    },
  },
  paper: {
    width: "100%",
    marginBottom: "20px",
  },
  header: {
    fontWeight: "bold",
    padding: "10px",
  },
  toolbar: {
    fontSize: "20px",
    padding: "10px",
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  card: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    padding: "10px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    marginBottom: "10px",
  },
}));

interface TableProps<T> {
  data: T[];
  filterColumns?: string[];
  checkDisabled?: (item: T) => boolean;
  dateFormat?: string;
  limit?: number;
  noRecordsMessage?: string;
  pagination?: boolean;
  total?: number; // total number of pages
  onPageChange?: (page: number, limit: number) => void; // function to handle page change
  handleSearch?: (value: string) => void;
  heading?: string | ReactElement;
  toolbar?: boolean;
  rowClassName?: (item: T) => string; // added rowClassName prop
}

export function useTable<T>(props: TableProps<T>) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const {
    data,
    filterColumns,
    checkDisabled,
    dateFormat = "MMM D YYYY hh:mm A",
    noRecordsMessage,
    handleSearch,
    rowClassName, // added rowClassName to destructuring
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props?.limit || 10);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<string | null>(null);
  const [query, setQuery] = useState<string>("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (props.onPageChange) {
      props.onPageChange(newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (props.onPageChange) {
      props.onPageChange(0, newRowsPerPage);
    }
  };

  const handleSort = (field: string) => {
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearchQuery = (value: string) => {
    setQuery(value);
    if (handleSearch) {
      handleSearch(value);
    }
  };

  if (data?.length === 0)
    return (
      <Grid container spacing={2}>
        <Paper className="w-100">
          <Grid>
            <h2 className="m-3">{props.heading}</h2>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="p-5"
          >
            <NoRecords
              message={noRecordsMessage || "No records found for this table"}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setQuery("");
                props.handleSearch && props.handleSearch("");
              }}
              hidden={!query}
            >
              Reset
            </Button>
          </Grid>
        </Paper>
      </Grid>
    );

  const sortedData = [...data].sort((a, b) => {
    if (sortField) {
      if (a[sortField] < b[sortField]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortOrder === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredKeys = Object.keys(sortedData[0] as object).filter(
    (key) => !filterColumns?.includes(key)
  );

  const displayedData = props.pagination
    ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortedData;

  if (isSmallScreen) {
    return (
      <Grid container spacing={2}>
        {displayedData.map((item, index) => (
          <Grid item xs={12} key={index} className={`${classes.card} ${rowClassName ? rowClassName(item) : ""}`}>
            <strong>S.No:</strong> {index + 1 + page * rowsPerPage}
            {filteredKeys.map((key) => (
              <Grid item xs={12} key={key}>
                <strong>{key.toUpperCase()}:</strong> {Array.isArray(item[key])
                  ? item[key].join(", ")
                  : typeof item[key] === "string" &&
                    moment(item[key], moment.ISO_8601, true).isValid() &&
                    dateFormat
                  ? moment(item[key]).format(dateFormat)
                  : item[key]}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Paper elevation={1} className={classes.paper}>
      <Grid hidden={!props.toolbar} container justifyContent="space-between">
        <Grid item xs={12} sm={6}>
          <h2 className={classes.toolbar}>{props.heading}</h2>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            placeholder="Search..."
            onChange={(e) => handleSearchQuery(e.target.value)}
            size="small"
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell
                hidden={filterColumns?.includes("s_no")}
                className={classes.header}
              >
                S.No
              </TableCell>
              {filteredKeys.map((key) => (
                <TableCell key={key} align="center" className={classes.header}>
                  <TableSortLabel
                    active={sortField === key}
                    direction={sortOrder}
                    onClick={() => handleSort(key)}
                  >
                    {key.toUpperCase()}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((item, index) => (
              <TableRow
                key={index}
                className={`${classes.row} ${
                  checkDisabled?.(item) ? classes.disabled : ""
                } ${rowClassName ? rowClassName(item) : ""}`} // ensure this line is present
              >
                <TableCell align="center">
                  {index + 1 + page * rowsPerPage}
                </TableCell>
                {filteredKeys.map((key) => (
                  <TableCell key={key} align="center">
                    {Array.isArray(item[key])
                      ? item[key].join(", ")
                      : typeof item[key] === "string" &&
                        moment(item[key], moment.ISO_8601, true).isValid() &&
                        dateFormat
                      ? moment(item[key]).format(dateFormat)
                      : item[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {props.pagination && (
        <TablePagination
          rowsPerPageOptions={[props.limit || 5]}
          component="div"
          count={props.total || data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
