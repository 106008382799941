import {
  Box,
  Typography,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Button,
  Table,
} from "@material-ui/core";
import { useTestContext } from "context/testProvider";
import { useModalContext } from "context/modalProvider";

export default function Submit(props): JSX.Element {
  const testContext = useTestContext();
  if (!testContext) {
    throw new Error("Submit must be used within a TestProvider");
  }

  const { test, answers, submitTest } = testContext;
  const totalquestion = test?.questions?.length || 0;
  const { openModal, closeModal } = useModalContext();

  const answered = answers.filter(
    (answer) => answer.selectedOptions?.length > 0 || answer?.textAnswer !== ""
  ).length;

  const handleOpen = () => {
    testContext.setIsInSubmitMode(true);
    openModal(
      "Submit Test", // title
      <Box>
        <Typography variant="h6" className="text-center">
          {test?.name} {/* Test name */}
        </Typography>
        <Typography variant="subtitle1" className="text-center">
          Time spent: {props.timeSpent} {/* Time spent */}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Total questions</TableCell>
                <TableCell>{totalquestion}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Answered</TableCell>
                <TableCell>{answered}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Skipped</TableCell>
                <TableCell>{totalquestion - answered}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>,
      <Box mt={2}>
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <Button variant="contained" onClick={() => closeModal()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={submitTest}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>,
      "sm",
      () => testContext.setIsInSubmitMode(false)
    );
  };

  return (
    <Grid item xs={12} className="p-3 lead text-center border-bottom">
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Finish
      </Button>
    </Grid>
  );
}
