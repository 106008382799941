import { useGet_BillsQuery } from "generated/graphql";
import { useTable } from "hooks/useTable";
import moment from "moment";
import { useState } from "react";
import { Button, Chip, Grid, useTheme } from "@material-ui/core";
import { usePaymentModal } from "shared/modals/usePaymentModal";

const BillingTable = () => {
  const [startDate] = useState(
    moment().subtract(1, "years").format("YYYY-MM-DD")
  );
  const [endDate] = useState(moment().format("YYYY-MM-DD"));

  const openModal = usePaymentModal();

  // by default get for 1 year
  const { data } = useGet_BillsQuery({
    variables: {
      limit: 1000,
      offset: 0,
      _gt: startDate,
      _lte: endDate,
    },
  });

  // add action button to table data for payment
  const tableData = data?.examsonline_bill?.map((item) => {
    return {
      ...item,
      action: (
        <Button variant="contained" onClick={() => openModal(item.id)}>
          Pay
        </Button>
      ),
      status: <PaymentStatus status={item.status.toUpperCase()} />,
      startDate: moment(item.startDate).format("MMM D YYYY"),
      endDate: moment(item.endDate).format("MMM D YYYY"),
      amount: `$${item.amount}`,
    };
  });

  const table = useTable({
    data: tableData || [],
    filterColumns: ["id", "__typename", "plan", "orgId"],
    dateFormat: "MMM D YYYY",
    limit: 10,
    noRecordsMessage: "No billing records found",
    pagination: true,
  });

  return (
    <Grid container spacing={3} className="m-5">
      <Grid item xs={12}>
        {table}
      </Grid>
    </Grid>
  );
};

export default BillingTable;

const PaymentStatus = ({ status }) => {
  let color, label;

  const theme = useTheme();
  console.log("status", status);

  switch (status) {
    case "PAID":
      color = "primary";
      label = "Paid";
      break;
    case "PENDING":
      color = "secondary";
      label = "Unpaid";
      break;
    case "INREVIEW":
      color = theme.palette.warning.main;
      label = "In Review";
      break;
    default:
      color = "default";
      label = "PENDING";
      break;
  }

  return <Chip label={label} color={color} />;
};
