import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./banner.scss";

const useStyles = makeStyles((theme) => ({
  bannerError: {
    padding: theme.spacing(2, 1),
    margin: theme.spacing(2, 1),
    textAlign: "center",
  },
  image: {
    width: "25%",
  },
  message: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  message?: string;
}

export default function ErrorBanner(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.bannerError}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <img
            src={"/error.svg"}
            alt="Error"
            className={classes.image}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.message}>
            {!props?.message ? "Something went wrong, we cannot connect to our services" : props.message}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
