import { useState, useEffect } from "react";

export function useNetworkStatus(apiUrl: string) {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const checkBackendStatus = async () => {
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 seconds timeout

        const response = await fetch(apiUrl, { signal: controller.signal });
        clearTimeout(timeoutId);

        if (response.ok) {
          setIsOnline(true);
        } else {
          setIsOnline(false);
        }
      } catch (error) {
        setIsOnline(false);
      }
    };

    checkBackendStatus();
    const intervalId = setInterval(checkBackendStatus, 60000); // check every 60 seconds

    return () => clearInterval(intervalId);
  }, [apiUrl]);

  return { isOnline };
}

