import { useState } from "react";

import { useAddOption } from "../../../shared/hooks/tests/useAddOption";
import { useDeleteOptionsByQuesId } from "../../../shared/hooks/tests/useDeleteOptionsByQuesId";
import { optionInitialState } from "../../create-test/_store/create-test.reducer";
import { QuestionType } from "../../enums/question.type.enum";
import { EditOptionsNext } from "./options";
import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import {
  Examsonline_Questions,
  useUpdate_Question_By_IdMutation,
} from "generated/graphql";
import { Alert } from "@material-ui/lab";

interface Props {
  question: Examsonline_Questions;
}

/** functional component */

export function OptionsTabNext(props: Props) {
  const { question } = props;

  const { deleteOptions } = useDeleteOptionsByQuesId(question.id);
  const { addOptions } = useAddOption();
  const [updatedQuestion] = useUpdate_Question_By_IdMutation();

  const handleTypeChange = async (type: QuestionType) => {
    await updatedQuestion({
      variables: {
        _eq: question.id,
        _set: {
          type,
        },
      },
    });
  };

  const [defaultTab, setdefaultTab] = useState(question.type);

  const tabSelected = async (event: any, tab: any) => {
    await deleteOptions(question.id);

    if (tab === QuestionType.TEXT) {
      setdefaultTab(QuestionType.TEXT);
      handleTypeChange(QuestionType.TEXT);
    } else {
      setdefaultTab(QuestionType.MCQ);

      addOptions([
        { ...optionInitialState, type: 0, q_id: question.id },
        { ...optionInitialState, type: 0, q_id: question.id },
      ]);

      handleTypeChange(QuestionType.MCQ);
    }
  };

  if (!props || !question.id) return <p></p>;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper square>
            <Tabs
              centered
              value={defaultTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={tabSelected}
              aria-label="disabled tabs example"
            >
              <Tab
                style={{ fontSize: "1.2em" }}
                value={QuestionType.MCQ}
                label="Multiple Choice"
              />
              <Tab
                style={{ fontSize: "1.2em" }}
                value={QuestionType.TEXT}
                label="Text Answer"
              />
            </Tabs>
          </Paper>
          <div hidden={defaultTab !== QuestionType.MCQ} title="Multiple Choice">
            <EditOptionsNext q_id={question.id}></EditOptionsNext>
          </div>
          <div
            hidden={defaultTab !== QuestionType.TEXT}
            title="Text Answer"
            className="p-2"
          >
            <Alert severity="info">
              <strong>Text Answer</strong> give a correct answer for the
              question, our system will match this answer with the student's
              answer.
            </Alert>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
