import { useEffect, useState } from "react";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import "./timer.scss";

interface Props {
  expiryTime: number; // minutes
  onEndTimer: Function;
}

export function Timer(props: Props) {
  const [interval, setinterval] = useState<any>();
  const [timeleftSec, settimeleftSec] = useState(props.expiryTime * 60);

  useEffect(() => {
    setinterval(
      setInterval(() => {
        settimeleftSec((timeleftSec) => timeleftSec - 1);
      }, 1000)
    );

    return () => {
      clearInterval(interval);
    };
  }, [props.expiryTime, timeleftSec]);

  const getPerTime = (): number => {
    if (!!props.expiryTime && timeleftSec <= 0) {
      props.onEndTimer();
    }

    return Math.round((timeleftSec / props.expiryTime) * 60) * 100;
  };

  const getProgress = () => {
    if (getPerTime() > 10) {
      return (
        <LinearProgress
          variant="determinate"
          value={getPerTime()}
          color="primary"
        />
      );
    }
    if (getPerTime() <= 10 && getPerTime() > 5) {
      return <LinearProgress variant="determinate" value={getPerTime()} color="secondary" />;
    }

    if (getPerTime() > 0 && getPerTime() < 2 * 60) {
      return <LinearProgress variant="determinate" value={getPerTime()} color="secondary" />;
    }

    return <Typography>Time Over</Typography>;
  };

  return (
    <Box className="p-1 font-weight-bold timer">
      <Typography variant="h6" className="text-dark mr-2">
        {Math.trunc(timeleftSec / 60)} mins {timeleftSec % 60} secs
      </Typography>
      {getProgress()}
    </Box>
  );
}
