import GenericModal from "component/genericModal";
import { createContext, useState, useContext } from "react";

// Define the type for the modal state
interface ModalState {
  id: string;
  isOpen: boolean;
  title: string;
  content: JSX.Element | null;
  footer: JSX.Element | null;
  size: string;
  onBeforeClose?: () => void; // Add onBeforeClose method
  closeAllModals: () => void;
}

// Create the Modal context
const ModalContext = createContext<{
  modals: ModalState[];
  openModal: (
    title: string,
    content: JSX.Element | null,
    footer: JSX.Element | null,
    size: string,
    onBeforeClose?: () => void // Add onBeforeClose parameter
  ) => void;
  closeModal: (id?: string) => void;
  closeAllModals: () => void;
}>({
  modals: [],
  openModal: () => {},
  closeModal: () => {},
  closeAllModals: () => {},
});

// Create the Modal provider component
export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState<ModalState[]>([]);

  const openModal = (
    title: string,
    content: JSX.Element | null,
    footer: JSX.Element | null,
    size: string,
    onBeforeClose?: () => void
  ) => {
    const id = Math.random().toString(36).substr(2, 9); // Generate a random ID
    setModals([
      ...modals,
      { id, isOpen: true, title, content, footer, size, onBeforeClose, closeAllModals },
    ]);
  };

  const closeModal = (id?: string) => {
    if (!id) {
      // Close the topmost modal
      const topModal = modals[modals.length - 1];
      if (topModal?.onBeforeClose) {
        topModal.onBeforeClose();
      }
      setModals(modals.slice(0, -1));
      return;
    }

    // Close a specific modal
    const modalToClose = modals.find((modal) => modal.id === id);
    if (modalToClose?.onBeforeClose) {
      modalToClose.onBeforeClose();
    }

    setModals(modals.filter((modal) => modal.id !== id));
  };

  const closeAllModals = () => {
    modals.forEach((modal) => {
      if (modal.onBeforeClose) {
        modal.onBeforeClose();
      }
    });
    setModals([]);
  };

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal, closeAllModals }}>
      {children}
      {modals.map((modal) => (
        <GenericModal
          key={modal.id}
          isOpen={modal.isOpen}
          handleClose={() => closeModal(modal.id)}
          title={modal.title}
          content={modal.content}
          footer={modal.footer}
          size={modal.size}
        />
      ))}
    </ModalContext.Provider>
  );
};

// Create the custom hook
export const useModalContext = () => {
  return useContext(ModalContext);
};
