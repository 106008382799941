import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import { Comments } from "shared/components/comments/comments";

const AddBalance: React.FC = () => {
  const [method, setMethod] = useState(0); // 0 for UPI, 1 for QR Code
  const [upiId, setUpiId] = useState("");
  const [amount, setAmount] = useState("");
  const [proof, setProof] = useState<File | null>(null);
  const billing_id = "your-billing-id"; // Replace with actual billing ID

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setMethod(newValue);
  };

  const handleUpiIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpiId(event.target.value);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const handleProofChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setProof(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    try {
      // Implement the logic to handle the form submission
      alert("Balance added successfully!");
    } catch (error) {
      console.error("Error adding balance:", error);
      alert("Failed to add balance.");
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Add Balance
      </Typography>

      <Tabs
        value={method}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="UPI" />
        <Tab label="QR Code (PayPal)" />
      </Tabs>

      <Box mt={2}>
        <TextField
          fullWidth
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          margin="normal"
          required
        />

        {method === 0 && (
          <>
            <Grid item xs={12} className="d-flex justify-content-center">
              <QRCode value="Payment URL" />
            </Grid>
          </>
        )}

        {method === 1 && (
          <Grid container spacing={3} className="d-flex">
            <Grid item xs={12} className="d-flex">
              <Typography variant="h6" className="m-auto">
                Payment Details
              </Typography>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center">
              <Typography>
                Please scan the QR code below to make a payment:
              </Typography>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center">
              <QRCode value="Payment URL" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="file"
                onChange={handleProofChange}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Comments
                placeholder="Please provide proof of your payment"
                ref_id={billing_id}
                enableAddComment={true}
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Confirm
      </Button>
    </Container>
  );
};

export default AddBalance;
