import { Grid } from "@material-ui/core";
import {  useRef } from "react";

const ProPlanWrapper = ({ children, isProUser }) => {
  const wrapperRef = useRef(null);


  return <Grid container ref={wrapperRef}>{children}</Grid>;
};

export default ProPlanWrapper;
