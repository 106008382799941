import React, { createContext, useContext, useState, useMemo, useEffect } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const ThemeContext = createContext({
  toggleTheme: () => {},
  isDarkTheme: false,
});

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem("isDarkTheme");
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  const toggleTheme = () => {
    setIsDarkTheme((prev) => {
      const newTheme = !prev;
      localStorage.setItem("isDarkTheme", JSON.stringify(newTheme));
      return newTheme;
    });
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          type: isDarkTheme ? "dark" : "light",
          background: {
            default: isDarkTheme ? "#121212" : "#ffffff",
          }
        },
      }),
    [isDarkTheme]
  );

  return (
    <ThemeContext.Provider value={{ toggleTheme, isDarkTheme }}>
      <ThemeProvider theme={theme}>
        <Grid container style={{ backgroundColor: theme.palette.background.default }}>
          {children}
        </Grid>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
