import { Card, Grid, Typography, IconButton } from "@material-ui/core";
import { ContextBadge } from "component/contextBadge";
import { Examsonline_Roles } from "generated/graphql";
import { FaCopy } from "react-icons/fa";

interface Props {
  role: Examsonline_Roles;
}
export default function RoleCard(props: Props) {
  enum RoleColor {
    "STUDENT" = "success",
    "AUTHOR" = "primary",
    "SUPPORT" = "warning",
  }
  const copyUrl = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <Card className="border card-animate  mt-2 w-100 p-2">
      <Grid container>
        <Grid item xs={6} className="m-auto text-left" lg={3}>
          <ContextBadge variant={RoleColor[props.role.role]}>
            {props.role.role}
          </ContextBadge>
        </Grid>
        <Grid item xs={6} className="m-auto" lg={4}>
          <Typography variant="body1">
            {props.role?.organisation.name}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} className="m-auto  text-center" lg={5}>
          <code style={{ fontSize: "1.4em" }}>
            {props.role?.organisation.id}
          </code>
          <IconButton
            title="copy organisation id"
            aria-label="copy organisation id"
            onClick={() => copyUrl(props.role.organisation.id)}
          >
            <FaCopy size={20} />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
}
