import "./table.scss";
import Axios from "axios";
import { useState } from "react";
import Moment from "react-moment";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Book,
  CheckTwoTone,
  ClearTwoTone,
  GetApp,
  Visibility,
} from "@material-ui/icons";
import { IconButton, Paper, TextField, LinearProgress, Chip } from "@material-ui/core";
import { Helper } from "helper/helper";
import NoRecords from "component/banners/no-records";
import { Roles } from "models/roles";
import { useAnswerSheet } from "hooks/useAnswerSheet";
import { useViewResultModal } from "hooks/useViewResultModal";

interface Props {
  data: any[];
  showsearch?: boolean;
  viewAnswerSheet?: (id: string) => void;
}

export default function ResultGrid(props: Props) {
  const [search, setsearch] = useState("");
  const [student_id, setstudent_id] = useState<any>("");

  const { AnswerSheetDrawer, toggleAnswerSheet } = useAnswerSheet();
  const { viewPage } = useViewResultModal("result");

  const role = Helper.getUserRole();

  const handleOnChange = (e: any) => {
    setsearch(e.target.value);
  };

  const filter = (test: string, str: string) => {
    if (!!str) {
      return test.includes(str);
    }
    return true;
  };

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Candidate",
      flex: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="font-krona my-auto">{params.value}</p>;
      },
    },
    {
      field: "createdAt",
      headerName: "Started",
      flex: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => getcreatedDate(params),
    },
    {
      field: "updatedAt",
      headerName: "Ended",
      flex: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => getupdatedDate(params),
    },
    {
      field: "correct",
      headerName: "Stats",
      flex: 100,
      editable: false,
      renderCell: (params: GridRenderCellParams) => getCorrect(params),
    },
    {
      field: "percentage",
      headerName: "Percentage",
      flex: 200,
      editable: false,
      resizable: true,
      renderCell: (params: GridRenderCellParams) =>
        progressBarFormatter(params),
    },
    {
      field: "id",
      headerName: "Answers",
      flex: 100,
      editable: false,
      renderCell: (params) => toggleAnswerSheet(params.row.id),
    },
    {
      field: "completed",
      headerName: "status",
      flex: 50,
      editable: false,
      renderCell: (params: GridRenderCellParams) => getCompleted(params),
    },
    {
      field: "id",
      headerName: "Actions",
      flex: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams) => getDownload(params),
    },
  ];

  const getcreatedDate = (cell: any) => {
    return <Moment format="DD-MMM-YYYY hh:mm A">{cell.row.createdAt}</Moment>;
  };
  const getupdatedDate = (cell: any) => {
    return (
      <Moment className="text-primary" format="DD-MMM-YYYY hh:mm A">
        {cell.row.updatedAt}
      </Moment>
    );
  };

  const progressBarFormatter = (value: any) => {
    const variants = ["success", "warning", "danger"];
    let selected = 0;
    if (value.row.percentage < 70 && value.row.percentage >= 45) {
      selected = 1;
    }
    if (value.row.percentage < 45) {
      selected = 2;
    }
    return (
      <LinearProgress
        className="text-black my-2 w-100 h-50"
        variant="determinate"
        value={value.row.percentage}
      />
    );
  };

  const getCorrect = (value: any) => {
    return (
      <span>
        <Chip className="p-2" color="primary" label={value.row.correct} />
        <Chip className="p-2 mx-2" color="secondary" label={value.row.wrong} />
        <Chip className="p-2" color="default" label={value.row.skipped} />
      </span>
    );
  };

  const getCompleted = (value: any) => {
    return (
      <span>
        {value ? <CheckTwoTone></CheckTwoTone> : <ClearTwoTone></ClearTwoTone>}
      </span>
    );
  };

  const download = (id: number) => {
    Axios.get(`result/download/${id}`, { responseType: "arraybuffer" }).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
      }
    );
  };

  const getDownload = (value: any) => {
    return (
      <p data-testid="actions" className="my-1">
        <IconButton aria-label="" onClick={() => viewPage("", value.row.id)}>
          <Visibility />
        </IconButton>
        <IconButton aria-label="" onClick={() => download(value)}>
          <GetApp></GetApp>
        </IconButton>
        <IconButton
          hidden={role !== (Roles.AUTHOR || Roles.ORGADMIN)}
          aria-label=""
          onClick={() => toggleAnswerSheet(value.row.id)}
        >
          <Book />
        </IconButton>
      </p>
    );
  };

  if (!props.data || props?.data?.length === 0)
    return <NoRecords message="No Results Found" />;

  return (
    <div>
      <TextField
        hidden={props?.showsearch}
        onChange={handleOnChange}
        value={search}
        size="small"
        type="text"
        placeholder="Search result by e-mail"
        variant="outlined"
        fullWidth
      />
      <Paper className="w-100">
        <DataGrid
          autoHeight
          rows={props.data.filter((a) => filter(a.email, search))}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          density="compact"
          disableColumnFilter
        />
      </Paper>
      <AnswerSheetDrawer />
    </div>
  );
}
