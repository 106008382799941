import { Button, Grid, GridSize } from "@material-ui/core";
import draftToHtml from "draftjs-to-html";

import { Option } from "../../../../models/option.model";

const SelectOption: React.FC<{
  option: Option;
  index: number;
  onOptionClick: (op: Option) => void;
  isSelected: boolean;
  size?: GridSize;
}> = ({ option, index, onOptionClick, isSelected, size }) => {
  return (
    <Grid
      item
      onClick={() => onOptionClick(option)}
      xs={12}
      md={size || 5}
      className="option-index rounded border-left-primary \ border option pointer m-2 mx-auto"
    >
      <Grid
        container
        className={isSelected ? "h-100 selected border p-1" : "h-100 p-1"}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={2}
          md={2}
          className="d-flex align-items-center text-center"
        >
          <Button variant="contained" size="small">
            {index + 1}
          </Button>
        </Grid>
        <Grid
          item
          xs={10}
          md={10}
          alignContent="center"
          alignItems="center"
          className=" p-3 text-left"
          dangerouslySetInnerHTML={{
            __html: draftToHtml(JSON.parse(option?.value)),
          }}
        >
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectOption;
