import { useEffect, useState } from "react";
import Aos from "aos";
import Axios from "axios";
import { EditorState } from "draft-js";
import { Grid, LinearProgress, Button, IconButton } from "@material-ui/core";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Alert } from "@material-ui/lab";
import { Delete } from "@material-ui/icons";
import { Option } from "../../../models/option.model";
import { useAddOption } from "../../../shared/hooks/tests/useAddOption";
import { useGetOptions } from "../../../shared/hooks/tests/useGetOptions";
import { useDeleteOptions } from "../../../shared/hooks/tests/useDeleteOptions";
import { QuestionType } from "../../enums/question.type.enum";
import { optionInitialState } from "../../create-test/_store/create-test.reducer";

import "../view/option.scss";
import { IsCorrect } from "./iscorrect";
import GenericEditor from "component/genericEditor";

interface Props {
  q_id: number;
}

export function EditOptionsNext({ q_id: initialQId }: Props) {
  const [options, setOptions] = useState([
    optionInitialState,
    optionInitialState,
  ]);
  const [q_id, setQId] = useState(initialQId);
  const [valid, setValid] = useState<boolean>(false);
  const [showToolbar, setShowToolbar] = useState(false);

  const { addOptions } = useAddOption();
  const { deleteOption } = useDeleteOptions(q_id);
  const { optionsEditorState, error, loading } = useGetOptions(q_id);

  useEffect(() => {
    if (initialQId) {
      setQId(initialQId);
    }
  }, [initialQId]);

  useEffect(() => {
    setValid(options.some((opt: Option) => opt?.isCorrect));
  }, [options]);

  useEffect(() => {
    if (optionsEditorState?.length) {
      Aos.init();
      setOptions(optionsEditorState);
    }
  }, [optionsEditorState]);

  const uploadImageCallBack = (file: File) => {
    const formData = new FormData();
    formData.append(file.name, file);
    return Axios.post("/file/image/editor", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const updateStore = () => {
    console.log("updating store", options);
    addOptions(
      options
        .filter((opt) => opt.type !== QuestionType.TEXT)
        .map((opt) => ({ ...opt, q_id }))
    );
  };

  const addOption = () => {
    setOptions((prev) => [...prev, { ...optionInitialState, q_id }]);
  };

  const removeOption = (id?: number) => {
    deleteOption(id || options[options.length - 1]?.id);
    setOptions((prev) => prev.filter((opt) => opt.id !== id));
  };

  const handleEditorChange = (state: EditorState, id: number) => {
    setOptions((prev) =>
      prev.map((opt, idx) => (idx === id ? { ...opt, value: state } : opt))
    );
  };

  const handleFocus = () => setShowToolbar(true);

  if (error) return <p>An error occurred</p>;

  return (
    <Grid container
      onMouseLeave={() => setShowToolbar(false)}
      spacing={2}
    >
      {loading && <LinearProgress />}
        {!valid && (
          <Grid item xs={12}>
            <Alert severity="warning">
              Every question must have at least one correct answer.
            </Alert>
          </Grid>
        )}
        {options.map((option, index) => (
          <>
            <Grid item xs={2} className="correct-indicator">
              <IsCorrect key={option.id} option={option} />
            </Grid>
            <Grid item xs={8} className="editor-container">
              <GenericEditor
                editorState={option.value}
                onEditorStateChange={(state) =>
                  handleEditorChange(state, index)
                }
                placeholder="Enter option text here"
                toolbarOptions={{
                  options: ["inline", "list", "image"],
                  inline: { inDropdown: false },
                  list: { inDropdown: true },
                  image: { uploadCallback: uploadImageCallBack },
                }}
                wrapperClassName="editor-wrapper"
                editorClassName="editor"
                onFocus={handleFocus}
                toolbarHidden={!showToolbar}
              />
            </Grid>
            <Grid item xs={2} className="delete-button">
              <IconButton
                onClick={() => removeOption(option.id)}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            </Grid>
          </>
        ))}
        <Grid container item xs={12} justifyContent="center">
          <Button
            variant="text"
            color="primary"
            onClick={addOption}
            startIcon={<FaPlus />}
            size="small"
          >
            Add Option
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={() => removeOption(options[options.length - 1]?.id)}
            startIcon={<FaMinus />}
          >
            Delete Option
          </Button>
      </Grid>
    </Grid>
  );
}
