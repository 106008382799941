import { Container, Grid, Typography } from "@material-ui/core";
import "./test-library.scss";
import TestGrid from "shared/grids/test-grid";
import { TestCardColumns } from "shared/components/cards/test-card/testCard";

interface Props {
  showHeader?: boolean;
}

export function TestLibrary(props: Props) {
  return (
    <Container className="library-tests">
      <Grid container spacing={2} className="pt-5">
        <Grid item xs={12} hidden={!props.showHeader}>
          <Typography variant="h3" className="font-krona">
            Tests Library
          </Typography>
          <Typography variant="subtitle1" className="text-secondary px-4">
            Find tests from all over the world
          </Typography>
        </Grid>
        <TestGrid
          hideColumns={[
            TestCardColumns.ACTIONS,
            TestCardColumns.IMPORT,
            TestCardColumns.CREATEDAT,
            TestCardColumns.STATS,
            TestCardColumns.NEW_SESSION,
            TestCardColumns.BUY
          ]}
          limit={8}
          spacing={3}
          variant="inline"
        />
      </Grid>
    </Container>
  );
}
