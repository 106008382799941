import { Component } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Result } from "../../models/result.model";
import "./result.scss";

export interface Props {
  history: any;
  data: Result;
}

class SecondContainer extends Component<Props> {
  render() {
    return (
      <Container className="pt-3" id="second" maxWidth="md">
        <Grid container spacing={4} justifyContent="center">
          <Grid item md={10} className="alignCenter">
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>
                <Card className="font-weight-bold bg-success">
                  <CardContent>
                    <Typography variant="h6" align="center">
                      {this.props.data?.attempt.metadata.correct} Correct
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className="bg-warning">
                  <CardContent>
                    <Typography variant="h6" align="center">
                      {this.props.data?.attempt.metadata.skipped} Skipped
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className="font-weight-bold text-white bg-dark">
                  <CardContent>
                    <Typography variant="h6" align="center">
                      {this.props.data.attempt.metadata.incorrect} Wrong
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default SecondContainer;
