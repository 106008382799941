import { HTMLProps } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./banner.scss";

const useStyles = makeStyles((theme) => ({
  bannerNoRecords: {
    textAlign: "center",
    padding: theme.spacing(5, 1),
  },
  jumbotron: {
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
}));

export default function TestOffline(props: HTMLProps<any>) {
  const classes = useStyles();

  return (
    <Container hidden={Boolean(props.hidden)} className={classes.bannerNoRecords}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.jumbotron}>
            This Test is currently offline and cannot be attempted. Please check
            with the author of this test to enable it.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
