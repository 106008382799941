import "../cards.scss";
import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
  FaCartPlus,
  FaCheck,
  FaPencilAlt,
  FaTrash,
  FaCopy,
  FaSmile,
  FaMeh,
  FaFrown,
} from "react-icons/fa";
import {
  GET_TEST_BY_ID,
  MY_ORDERS,
  SEARCH_TEST_BY_TAG,
} from "../../../../queries/queries";
import Loading from "../../loader/loading";
import { useToast } from "shared/hooks/useToast";
import useDeleteTest from "shared/hooks/tests/useDeleteTest";
import { FileCopy, List } from "@material-ui/icons";
import {
  Button,
  ButtonGroup,
  CardMedia,
  Container,
  Grid,
  GridSize,
  Paper,
  Typography,
} from "@material-ui/core";
import { useCopyQuestions } from "shared/hooks/tests/useCopyQuestions";
import { useHistory } from "react-router-dom";
import { Examsonline_Tests, useOrder_ItemMutation } from "generated/graphql";
import { useGradient } from "hooks/useGradient";
import { Tags } from "shared/components/tags/tags";
import { useQuestionsModal } from "shared/hooks/modals/useQuestionModal";
import { Helper } from "helper/helper";
import useTestAnalytics from "component/analytics/hooks/useTestAnalytics";
import { useDuplicateTest } from "shared/hooks/tests/useDuplicateTest";
import { useApolloClient } from "@apollo/client";
import MarksInput from "component/marksInput";
import { DateComponent } from "component/date";
import { ContextBadge } from "component/contextBadge";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export enum TestCardColumns {
  "MEDIA",
  "SELECT",
  "ACTIONS",
  "CREATEDAT",
  "STATS",
  "ASSIGN",
  "IMPORT",
  "CHANGE_STATUS",
  "BUY",
  "SHARE",
  "TOTAL",
  "MORE_INFO",
  "Name",
  "ANALYTICS",
  "NEW_SESSION",
  "DUPLICATE",
}

interface Props {
  test: Examsonline_Tests;
  edit: any;
  readonly?: any;
  hideColumns?: TestCardColumns[];
  isSelectable?: boolean;
  onSelect?: (test: Examsonline_Tests) => void;
}

const TestDifficulty = ({
  difficulty,
  onClick,
}: {
  difficulty: number;
  onClick: () => void;
}) => {
  const getVariant = (difficulty: number) => {
    if (difficulty < 40) return "success";
    if (difficulty < 70) return "warning";
    return "danger";
  };

  const getIcon = (difficulty: number) => {
    if (difficulty < 40) return <FaSmile size="15" />;
    if (difficulty < 70) return <FaMeh size="15" />;
    return <FaFrown size="15" />;
  };

  return (
    <div className="my-2" onClick={onClick} style={{ cursor: "pointer" }}>
      <ContextBadge size="small" variant={getVariant(difficulty)} className="mr-2">
        {getIcon(difficulty)}{" "}
        {difficulty < 40 ? "Easy" : difficulty < 70 ? "Medium" : "Hard"}
      </ContextBadge>
    </div>
  );
};

const CardColumn = ({
  colName,
  children,
  className,
  hidden,
  size = "medium",
  title,
}: {
  colName: TestCardColumns;
  children: React.ReactNode;
  className?: string;
  hidden?: boolean;
  title?: string;
  size?: "small" | "medium" | "large" | undefined;
}) => {
  const getSize = (
    size: "small" | "medium" | "large"
  ): { xs: GridSize; md: GridSize; lg: GridSize } => {
    switch (size) {
      case "small":
        return { xs: 6, md: 4, lg: 2 };
      case "medium":
        return { xs: 3, md: 2, lg: 1 };
      case "large":
        return { xs: 12, md: 6, lg: 4 };
      default:
        return { xs: 3, md: 2, lg: 1 };
    }
  };

  const gridSize = getSize(size);

  return (
    <Grid
      item
      xs={gridSize.xs}
      md={gridSize.md}
      lg={gridSize.lg}
      hidden={hidden}
      title={title}
      className={className}
    >
      {children}
    </Grid>
  );
};

const TestCardNext = (props: Props) => {
  const [loading] = useState(false);
  const history = useHistory();
  const { duplicateTest } = useDuplicateTest();
  const client = useApolloClient();
  const { successToast, dangerToast } = useToast();
  const { deleteTest: deleteTestMutation } = useDeleteTest(props?.test?.id);
  const { copyTest, loading: importingQuestions } = useCopyQuestions();
  const { getGradient } = useGradient();
  const { showModal } = useTestAnalytics();
  const [order_test, { loading: ordering_test }] = useOrder_ItemMutation({
    refetchQueries: [{ query: SEARCH_TEST_BY_TAG }, { query: MY_ORDERS }],
  });
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const order = () => {
    order_test({
      variables: {
        objects: {
          amount: props.test.price,
          test_id: props.test.id,
          status: true,
        },
      },
    })
      .then(() => successToast("Test has been ordered successfully"))
      .catch(() =>
        dangerToast(
          "Something went wrong ordering test, please try again later"
        )
      );
  };

  const editTest = () => history.push(`/addtest/${props.test.id}`);
  const viewTest = () => history.push(`/preview/${props.test.id}`);
  const onImport = () => copyTest(props.test.id);
  const handleSelect = () => props?.onSelect && props.onSelect(props.test);

  const deleteTest = () => {
    if (
      window.confirm(
        "Do you want to delete this test, once deleted all data will be lost!"
      )
    ) {
      deleteTestMutation()
        .then(() => successToast("Test Deleted Successfully"))
        .catch(() => dangerToast("Unable to delete test, an error occurred"));
    }
  };

  const handleDuplicate = async () => {
    const { data } = await client.query({
      query: GET_TEST_BY_ID,
      variables: { id: props.test.id },
    });

    const temp = cloneDeep(data.examsonline_tests[0]);
    delete temp.attempts_aggregate;
    delete temp.id;
    delete temp.createdAt;
    delete temp.files;
    temp.pincode = "1234";

    duplicateTest(temp);
  };

  const showQuestions = useQuestionsModal(props.test);
  const randomGradient = getGradient(props.test?.id);

  const shouldHide = (colName: TestCardColumns): boolean => {
    return props?.hideColumns?.includes(colName) || false;
  };

  if (!props?.test) {
    return (
      <Grid item xs={3} lg={2} className="bg-info p-1 m-1">
        <p className="m-2 text-center">Invalid Test</p>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1} className="p-2 p-lg-2">
      {(loading || importingQuestions || ordering_test) && <Loading />}
      <Paper
        style={{ width: "100%" }}
        className={
          props.test?.questions && Helper.validateExamsonlineTest(props.test)
            ? "rounded border-left-warning bg-warning"
            : ""
        }
      >
        <Grid item xs={12}>
          <Grid container>
            <CardColumn
              colName={TestCardColumns.MEDIA}
              className="m-0 p-2 d-flex justify-content-center align-items-center"
              size={isSmallScreen ? "large" : undefined}
            >
              <CardMedia
                onClick={viewTest}
                data-testid="test-card-thumbnail"
                style={{
                  width: "90%",
                  height: isSmallScreen ? "100px" : "100%",
                  backgroundImage:
                    props?.test?.files?.length > 0
                      ? `url(${props.test?.files[0]?.info?.location})`
                      : randomGradient,
                }}
                className="rounded"
                title="Test Thumbnail"
              />
            </CardColumn>
            <CardColumn
              colName={TestCardColumns.Name}
              className="test-name text-center m-auto font-krona"
            >
             <Typography style={{ fontWeight: 'bold' }}>{props.test.name}</Typography> 
            </CardColumn>
            <CardColumn
              colName={TestCardColumns.Name}
              className="d-flex my-auto"
            >
              <Tags tags={props.test.topics} variant="default" />
            </CardColumn>
            <CardColumn
              colName={TestCardColumns.TOTAL}
              className="text-left m-auto"
            >
              <MarksInput
                size="small"
                initialMarks={props.test.points}
                readonly
                questionId={props.test.id}
              />
            </CardColumn>
            <CardColumn
              colName={TestCardColumns.CREATEDAT}
              className="mt-md-2"
              title="Created Date"
              hidden={shouldHide(TestCardColumns.CREATEDAT)}
            >
              <span className="small">
                <ContextBadge className="text-black" variant="info">
                  created
                </ContextBadge>
              </span>
              <br />
              <DateComponent date={props.test.createdAt} />
            </CardColumn>
            <CardColumn
              colName={TestCardColumns.CREATEDAT}
              className="m-auto text-center"
              title="Updated Date"
              hidden={!!props.readonly}
            >
              <ContextBadge size="small" className="py-0 text-small" variant="warning">
                updated
              </ContextBadge>
              <DateComponent date={props.test.updatedAt} />
            </CardColumn>
            <CardColumn
              colName={TestCardColumns.CREATEDAT}
              className="test-name text-left m-auto"
              title="Author"
              hidden={!props.readonly}
            >
              {props.test.author}
            </CardColumn>
            <Grid item className="d-flex mt-1 p-3 align-items-center ">
              <Button
                className="m-0"
                variant="text"
                size="small"
                onClick={showQuestions}
              >
                <List fontSize="medium" />
                {props?.test?.questions_aggregate?.aggregate?.count}
                <span className="text-small">Questions</span>
              </Button>
            </Grid>
            <Grid item hidden={!props.isSelectable} className="m-auto">
              <Button
                title="Select"
                onClick={handleSelect}
                variant="outlined"
                color="primary"
              >
                Select
              </Button>
            </Grid>
            <Grid
              item
              hidden={shouldHide(TestCardColumns.IMPORT)}
              className="m-auto"
            >
              <Button
                className="m-auto"
                onClick={onImport}
                variant="text"
                size="small"
              >
                <FileCopy fontSize="small" /> Copy Q's
              </Button>
            </Grid>
            <CardColumn
              colName={TestCardColumns.BUY}
              className="text-center m-auto"
              hidden={shouldHide(TestCardColumns.BUY)}
            >
              {!props.test.orders_aggregate?.aggregate?.count ? (
                <Button
                  variant="outlined"
                  className="m-auto"
                  color="primary"
                  onClick={order}
                >
                  <FaCartPlus /> BUY {props.test.price}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="m-auto search-test-input"
                  onClick={viewTest}
                >
                  <FaCheck /> Purchased
                </Button>
              )}
            </CardColumn>
            <CardColumn colName={TestCardColumns.ANALYTICS} className="m-auto">
              <TestDifficulty
                difficulty={props.test?.metadata?.averageScore}
                onClick={() => showModal(props.test.id.toString())}
              />
            </CardColumn>
            <Grid
              item
              xs={12}
              lg={2}
              className={`${
                shouldHide(TestCardColumns.ACTIONS) || !!props.readonly
                  ? "d-none"
                  : "m-2 d-flex justify-content-around"
              }`}
            >
              <ButtonGroup variant="text" size="small">
                <Button onClick={editTest}>
                  <FaPencilAlt size={15} />
                </Button>
                <Button onClick={deleteTest} color="secondary">
                  <FaTrash size={15} />
                </Button>
                <Button onClick={handleDuplicate}>
                  <FaCopy size={15} />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container>
            {props.test?.questions &&
              Helper.validateExamsonlineTest(props.test)}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export { TestCardNext };
