import Axios from "axios";
import { Formik } from "formik";
import { Component } from "react";
import {
  Button,
  Container,
  Grid,
  Card,
  TextField,
  Typography,
  CardContent,
} from "@material-ui/core";
import { FaBitbucket } from "react-icons/fa";
import * as yup from "yup";
import { ProductType } from "../enums/product.enum";

export default class Recharge extends Component<any> {
  constructor(props: any) {
    super(props);
  }
  schema1 = yup.object({
    tests: yup
      .number()
      .integer("Number of tests cannot be fraction")
      .min(10)
      .required("Please enter the number of tests you want to add"),
  });
  submit(form: any) {
    Axios.post(`/payment/order`, {
      product: ProductType.PERSONAL,
      amount: form.tests * 1,
    })
      .then((res) => {
        this.props.history.push("/checkout", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} className="text-center">
            <Typography variant="h5" className="m-4">
              Add more Tests to your bucket <FaBitbucket />
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} className="m-auto">
            <Card>
              <CardContent>
                <Formik
                  validationSchema={this.schema1}
                  onSubmit={this.submit.bind(this)}
                  initialValues={{ tests: 100 }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <form id="right-box" noValidate onSubmit={handleSubmit}>
                      <Typography variant="subtitle1" className="p-1">
                        Number of Tests to add
                      </Typography>
                      <TextField
                        fullWidth
                        type="text"
                        name="tests"
                        value={values.tests}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.tests && !!errors.tests}
                        helperText={touched.tests && errors.tests}
                        placeholder="Enter Number of Test"
                        variant="outlined"
                        margin="normal"
                      />
                      <Typography variant="subtitle1">Price in INR</Typography>
                      <TextField
                        fullWidth
                        type="number"
                        disabled
                        value={values?.tests}
                        variant="outlined"
                        margin="normal"
                      />
                      <Grid container justify="center" className="startTestBtn">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          className="col-md-6 btn mr-2"
                        >
                          Goto Payment
                        </Button>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
