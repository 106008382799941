import { useCallback } from "react";
import { useModalContext } from "context/modalProvider";
import {
  Examsonline_Questions,
  Examsonline_Test_Sections,
} from "generated/graphql";
import { useTestById } from "../useTestById";
import QuestionAccordion from "shared/components/questions-accordian/question-accordian";

export function useQuestionsModal(testData) {
  const { openModal } = useModalContext();
  const { test } = useTestById(testData?.id);

  const openQuestionsModal = useCallback(() => {
    if (test) {
      openModal(
        `${test.name} - ${test.questions?.length} Questions`,
        <div style={{ minHeight: "50vh" }}>
          <QuestionAccordion
            questions={test.questions as Examsonline_Questions[]}
            sections={test.test_sections as Examsonline_Test_Sections[]}
          />
        </div>,
        null,
        "lg"
      );
    } else {
      openModal(`No Questions`, <div>No Questions</div>, null, "lg");
    }
  }, [test, openModal]);

  return openQuestionsModal;
}
