import { NavLink } from "react-router-dom";
import {
  FaUsers,
  FaCog,
  FaHeadset,
  FaHome,
  FaSignOutAlt
} from "react-icons/fa";
import { useAuth } from "context/authProvider";
import { useTheme } from "@material-ui/core";
import { AccountBalanceWallet } from "@material-ui/icons";

export const SideBarMenuList = () => {
  const { logout, role } = useAuth();
  const theme = useTheme();

  return (
    <ul
      className="nav flex-column nav-pills"
      style={{ backgroundColor: theme.palette.background.default }}
    >
      <li className="nav-item">
        <NavLink
          to={`/${role?.toLocaleLowerCase()}`}
          className="nav-link"
          activeClassName="active"
        >
          <FaHome />
          <span className="d-md-inline mx-1">Home</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/library" className="nav-link" activeClassName="active">
          <FaUsers />
          <span className="d-md-inline mx-1">Library</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/find" className="nav-link" activeClassName="active">
          <FaUsers />
          <span className="d-md-inline mx-1">Find Results</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to="/support/ask"
          className="nav-link"
          activeClassName="active"
        >
          <FaHeadset />
          <span className="d-md-inline mx-1">Support</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/profile" className="nav-link" activeClassName="active">
          <FaCog />
          <span className="d-md-inline mx-1">Settings</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/wallet" className="nav-link" activeClassName="active">
          <AccountBalanceWallet  fontSize="small"/>
          <span className="d-md-inline mx-1">Wallet</span>
        </NavLink>
      </li>

      {/* Divider */}
      <hr className="sidebar-divider d-none d-md-block" />

      <li className="nav-item">
        <div className="nav-link pointer" onClick={logout}>
          <FaSignOutAlt />
          <span className="d-md-inline mx-1"> Logout</span>
        </div>
      </li>
    </ul>
  );
};

export default SideBarMenuList;
