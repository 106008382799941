import { useCallback } from "react";
import { useModalContext } from "context/modalProvider";

interface UseGenericModalOptions {
  title: string;
  content: React.ReactNode;
  footer?: React.ReactElement | null;
  size?: "sm" | "md" | "lg";
}

export function useGenericModal() {
  const { openModal } = useModalContext();

  const openGenericModal = useCallback(
    (options: UseGenericModalOptions) => {
      const { title, content, footer = null, size = "medium" } = options;

      openModal(
        title,
        <div style={{ minHeight: "50vh" }}>
          {content}
        </div>,
        footer,
        size
      );
    },
    [openModal]
  );

  return { openGenericModal};
}