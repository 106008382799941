import React, { useState } from "react";
import { Maybe, useUpdate_Question_By_IdMutation } from "generated/graphql";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { GET_QUESTION_BY_ID } from "queries/queries";
import GenericEditor from "component/genericEditor";
import { Grid } from "@material-ui/core";

interface Props {
  explanation: Maybe<string>  | undefined;
  readOnly: boolean;
  questionId: number;
}

export const Explanation: React.FC<Props> = ({
  explanation,
  readOnly,
  questionId
}) => {
  const [editorState, setEditorState] = useState(() =>
    explanation
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(explanation)))
      : EditorState?.createEmpty()
  );
  const [updateExplanation] = useUpdate_Question_By_IdMutation({
    refetchQueries: [
      {
        query: GET_QUESTION_BY_ID,
        variables: { _eq: questionId },
      },
    ],
  });



  const handleEditorStateChange = (state: EditorState) => {
    setEditorState(state);
  };

  const handleSave = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = JSON.stringify(convertToRaw(contentState));

    await updateExplanation({
      variables: {
        _eq: questionId,
        _set: {
          explanation: rawContent,
        },
      },
    });
  };



  return (
    <Grid item xs={12}>
      <GenericEditor 
      toolbarHidden={readOnly}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        placeholder="Enter explanation here..."
        readOnly={readOnly}
        onFocus={() => {}}
        onBlur={handleSave}
      />
    </Grid>
  );
};
