import { useParams } from "react-router-dom";
import SessionGrid from "./sessionsGrid";

interface Props {
  testId: number;
}

export default function Sessions(props: Props): JSX.Element {
  const params: { id: string } = useParams();

  return <SessionGrid testId={parseInt(params?.id)} />;
}
