import { useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { OptionsReadonly } from "shared/components/options-readonly/option-readonly";
import { OptionsTabNext } from "component/question/edit/options-tab";
import {
  Examsonline_Questions,
  useUpdate_Question_By_IdMutation,
} from "generated/graphql";
import {
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { FileCopy, Lock, LockOpen } from "@material-ui/icons";
import { Explanation } from "./edit/explanation";
import { useCopyQuestions } from "shared/hooks/tests/useCopyQuestions";
import GenericEditor, { defaultToolbarOptions } from "component/genericEditor";
import { GET_TEST_BY_ID } from "queries/queries";

// Define question mode
export enum QuestionMode {
  readonly = "readonly",
  edit = "edit",
  copy = "copy",
}

interface QuestionProps {
  question: Examsonline_Questions;
  mode: QuestionMode;
  uploadImageCallBack: any;
}

export default function Question({
  question,
  mode,
  uploadImageCallBack,
}: QuestionProps) {
  if (!question?.text) return null;

  const [editMode, setEditMode] = useState(mode === QuestionMode.edit);
  const contentBlock = convertFromRaw(JSON.parse(question?.text || "{}"));

  const [updatedQuestion, { loading }] = useUpdate_Question_By_IdMutation({
    refetchQueries: [{ query: GET_TEST_BY_ID, variables: { id: question.id } }],
  });

  const editorState = contentBlock
    ? EditorState.createWithContent(contentBlock)
    : EditorState.createEmpty();

  const [currentEditorState, setEditorState] = useState(editorState);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const toggleMode = () => {
    if (mode !== QuestionMode.copy) {
      setEditMode(!editMode);
    }
  };

  const handleSave = async () => {
    await updatedQuestion({
      variables: {
        _eq: question.id,
        _set: {
          text: JSON.stringify(
            convertToRaw(currentEditorState.getCurrentContent())
          ),
        },
      },
    });
  };

  const { copyQuestion } = useCopyQuestions();

  const renderActionButtons = () => {
    switch (mode) {
      case QuestionMode.edit:
      case QuestionMode.readonly:
        return (
          <Tooltip title={editMode ? "Lock Editing" : "Unlock Editing"}>
            <IconButton onClick={toggleMode}>
              {editMode ? <LockOpen /> : <Lock />}
            </IconButton>
          </Tooltip>
        );
      case QuestionMode.copy:
        return (
          <Tooltip title="Copy Question">
            <IconButton
              onClick={() => {
                copyQuestion(question);
              }}
            >
              <FileCopy />
            </IconButton>
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <Paper elevation={3} className="p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="m-0">Question</h5>
        {renderActionButtons()}
      </div>
      {loading && <LinearProgress className="mb-3" />}
      <GenericEditor
        editorState={currentEditorState}
        onEditorStateChange={handleEditorStateChange}
        toolbarHidden={!editMode}
        onFocus={() => {}}
        toolbarOptions={{
          ...defaultToolbarOptions,
          hidetoolbar: !editMode,
        }}
        onBlur={handleSave}
        readOnly={!editMode}
      />
      <Grid container className="mt-3">
        {editMode ? (
          <OptionsTabNext question={question} />
        ) : (
          <OptionsReadonly options={question.options} selectedOptions={[]} />
        )}
      </Grid>

      <Grid container spacing={3} className="mt-3">
        <Grid item xs={12} className="mb-2">
          <h6 className="m-0">Explanation</h6>
        </Grid>
        <Grid item xs={12}>
          <Explanation
            readOnly={!editMode}
            questionId={question.id}
            explanation={question.explanation}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
