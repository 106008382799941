import { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Add, Delete, Publish } from "@material-ui/icons";
import { useTable } from "hooks/useTable";
import * as XLSX from "xlsx";

const useStyles = makeStyles({
  tableContainer: {
    minHeight: 400,
    overflow: "auto",
  },
});

const AllowListTable = ({ initialAllowList, onAllowListChange }) => {
  const [allowList, setAllowList] = useState(initialAllowList);
  const [newItem, setNewItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleAdd = () => {
    if (newItem.trim() === "") {
      alert("Item cannot be empty");
      return;
    }
    if (allowList.includes(newItem)) {
      alert("User already exists");
      return;
    }
    setAllowList([...allowList, newItem]);
    setNewItem("");
  };
  const handleDelete = (index) => {
    setAllowList(allowList.filter((_, i) => i !== index));
  };

  // Notify parent component of changes
  useEffect(() => {
    onAllowListChange?.(allowList);
  }, [allowList.length]);

  // convert allow list to have index
  const allowListWithIndex = allowList
    .filter((f) => f.includes(searchTerm))
    .map((item, index) => ({
      id: index,
      user: item,
      delete: (
        <Button variant="text" onClick={() => handleDelete(index)}>
          {" "}
          <Delete />{" "}
        </Button>
      ),
    }));

  const table = useTable({
    data: allowListWithIndex,
    filterColumns: ["id"],
    limit: 5,
    noRecordsMessage: "This session is open for everyone, add users to ristrict access",
    pagination: true
  });

  const classes = useStyles();

  const fileInputRef = useRef<any>(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setAllowList(data.flat());
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Grid container spacing={3} >
      <Grid item xs={12}>
        <Typography variant="h6" className="mt-5 ">
          Allow List
        </Typography>

      </Grid>
      <Grid item xs={12} sm={8}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs>
            <TextField
              value={newItem}
              onChange={(e) => setNewItem(e.target.value)}
              placeholder="Add new user eg. 12121212|test@test.com"
              fullWidth
            />
          </Grid>
          <Grid item >
            <IconButton className="\" color="primary" onClick={handleAdd}>
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>

      </Grid>
      <Grid item xs={12}>
        <input
          type="file"
          ref={fileInputRef}
          hidden
          onChange={handleFileUpload}
          accept=".xlsx,.xls"
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<Publish />}
          onClick={() => fileInputRef.current?.click()}
          size="small"
          fullWidth
        >
          Import users by Excel
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.tableContainer}>{table}</div>
      </Grid>
    </Grid>
  );
};
export default AllowListTable;
