import { WatchQueryFetchPolicy } from "@apollo/client";
import { Helper } from "../../helper/helper";
import { useGet_Test_By_IdQuery } from "generated/graphql";
import { Test } from "models/test.model";

export function useTestById(
  test_id: number | undefined | null,
  policy = "cache-first",
  omCompleted = () => {}
) {
  const { loading, data, error, refetch } = useGet_Test_By_IdQuery({
    variables: { id: Number(test_id) },
    fetchPolicy: policy as WatchQueryFetchPolicy,
    skip: !test_id,
    onCompleted: omCompleted,
  });

  return {
    test: data?.examsonline_tests[0],
    loading,
    error,
    data,
  refetch,
    testEditorstate: Helper.contentStateToEditorState(
      data?.examsonline_tests[0] as Test
    ),
  };
}
