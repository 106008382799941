import { Grid, TextField } from "@material-ui/core";
import { useGet_All_Test_Participants_ResultQuery } from "generated/graphql";
import { useState } from "react";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import Loading from "shared/components/loader/loading";
import ResultGrid from "shared/components/table/result-grid";
import { useParams } from "react-router-dom";

export function TestResult() {
  const [limit] = useState(5);
  const [total, settotal] = useState<number | undefined>(0);
  const [search, setsearch] = useState("");
  const [answersheets, setanswersheets] = useState<string[]>([]);
  const params: { id: string; asg_id: string } = useParams();
  const handleOnChange = (e: any) => {
    setsearch(e.target.value);
    reset();
  };

  const { pages, activePage, reset } = usePagintaion(total || 0, limit);

  const { data, loading } = useGet_All_Test_Participants_ResultQuery({
    variables: {
      limit: limit,
      offset: limit * (activePage - 1),
      _iregex: search,
      _eq: Number(params.id),
      asg_id: params.asg_id ? { _eq: params.asg_id } : {},
    },
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      settotal(data?.total.aggregate?.count);
    },
  });

  const viewAnswerSheet = (id: any) => {
    if (!answersheets.includes(id)) {
      setanswersheets((answersheets) => [...answersheets, id]);
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <Grid container>
        <Grid item xs={6} lg={4} className="m-auto">
          <TextField
            fullWidth
            variant="outlined"
            onChange={handleOnChange}
            value={search}
            type="text"
            placeholder="Search"
            size="small"
            className="m-auto \"
          />
        </Grid>

        <Grid
          item
          xs={6}
          lg={6}
          className="m-auto"
          style={{ maxWidth: "100%", height: "34px" }}
        >
          {pages()}
        </Grid>
      </Grid>
      <ResultGrid
        data={data?.examsonline_attempt || []}
        viewAnswerSheet={viewAnswerSheet}
        showsearch={true}
      />
    </div>
  );
}
