import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import draftToHtml from "draftjs-to-html";
import {
  Examsonline_Questions,
  Examsonline_Test_Sections,
} from "generated/graphql";
import { OptionsReadonly } from "../options-readonly/option-readonly";

const useStyles = makeStyles((theme) => ({
  sectionName: {
    margin: theme.spacing(2, 0),
    fontWeight: "bold",
    color: theme.palette.grey.A700,
  },
  questionAccordionContainer: {
    marginBottom: theme.spacing(2),
  },
  questionAccordion: {
    marginBottom: theme.spacing(1),
  },
}));

interface QuestionAccordionProps {
  questions: Examsonline_Questions[];
  sections: Examsonline_Test_Sections[];
}

const QuestionAccordion: React.FC<QuestionAccordionProps> = (props) => {
  const { questions, sections } = props;
  const classes = useStyles();

  return (
    <div className={classes.questionAccordionContainer}>
      {sections.map((section) => (
        <div key={section.id}>
          <Typography variant="h6" className={classes.sectionName} gutterBottom>
            {section.name}
          </Typography>
          {questions
            .filter((q) => q.section === section.id)
            .map((q, index) => (
              <Accordion key={q.id} className={classes.questionAccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${q.id}-content`}
                  id={`panel${q.id}-header`}
                >
                  <Grid container spacing={2} className="w-100">
                    <Grid item md={1} xs={12}>
                      <Button size="small" variant="contained" color="primary">
                        Q. {index + 1}
                      </Button>
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(JSON.parse(q?.text)),
                        }}
                      ></div>
                    </Grid>
                    <Grid item md={2} xs={12} container justify="flex-end">
                      <Typography variant="subtitle1">
                        Points: {q.marks}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} className="options">
                    <OptionsReadonly
                      options={q.options}
                      selectedOptions={[]}
                      textAnswer={q.explanation}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      ))}
    </div>
  );
};

export default QuestionAccordion;
