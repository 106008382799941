import { useEffect, useState } from "react";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import { Button, Grid, GridSize, GridSpacing, TextField } from "@material-ui/core";
import Loading from "shared/components/loader/loading";
import TestCards from "shared/components/cards/test-cards";
import { TestCardColumns } from "shared/components/cards/test-card/testCard";
import { useSearchTestByTopic } from "shared/hooks/tests/useSearchTestByTopic";
import { Examsonline_Tests } from "generated/graphql";
import { Add } from "@material-ui/icons";
import Formatter from "shared/translations/formatter";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TestCardSquare from "shared/components/cards/test-card/test-card-square";
import NoRecords from "component/banners/no-records";

interface Props {
  contest?: string;
  hideColumns?: TestCardColumns[];
  enableSelect?: boolean;
  onSelect?: (test: Examsonline_Tests) => void;
  canCreate?: boolean;
  variant?: "inline" | "square";
  limit?: number;
  spacing?: GridSpacing;
  suggestMode?: boolean;
  searchQuery?: string;
  squareColumns?: GridSize;
}
export default function TestGrid(props: Props) {
  const [limit] = useState(props.limit || 10);
  const [searchTest, setsearchTest] = useState(props.searchQuery || "");
  const [total, settotal] = useState(10);
  const history = useHistory();

  useEffect(() => {
    setsearchTest(props.searchQuery || "");
  }, [props.searchQuery]);

  const handleOnChange = (e: any) => {
    setsearchTest(e.target.value);
    reset();
  };

  const { pages, activePage, reset } = usePagintaion(total, limit);
  const { testsByTopic, totalcount, loading } = useSearchTestByTopic(
    limit,
    (activePage - 1) * limit,
    searchTest
  );

  const createTest = () => {
    history.push("/addtest");
  };

  const { t } = useTranslation("common");

  useEffect(() => {
    settotal(totalcount);
  }, [totalcount]);

  const renderCard = () => {
    if (props.variant === "square") {
      return testsByTopic?.map((t: Examsonline_Tests) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={props.squareColumns || 3}>
            <TestCardSquare test={t}></TestCardSquare>{" "}
          </Grid>
        );
      });
    }

    return (
      <TestCards
        isSelectable={props.enableSelect}
        hideColumns={props?.hideColumns}
        key={testsByTopic?.length}
        tableData={testsByTopic}
        onSelect={props.onSelect}
      />
    );
  };
  

  return (
    <Grid container spacing={props.spacing || 1}>
      {loading && <Loading />}
      {!props.suggestMode && (
        <>
          <Grid
            hidden={!props?.canCreate}
            className="text-center m-auto"
            item
            xs={6}
            lg={3}
          >
            <Button
              title={t("author.createTest", {})}
              onClick={createTest}
              className=" btn-create-test m-auto"
              variant="contained"
              size="small"
            >
              <Add /> <Formatter options={{}} text="author.createTest" />
            </Button>
          </Grid>
          <Grid
            data-testid="search-test"
            item
            xs={12}
            lg={props.canCreate ? 4 : 6}
            className="m-auto"
          >
            <TextField
              fullWidth
              variant="outlined"
              onChange={handleOnChange}
              value={searchTest}
              type="text"
              placeholder="Search"
              size="small"
              className="m-auto "
            />
          </Grid>
          <Grid item xs={12} lg={5} className="p-1 m-auto">
            {pages()}
          </Grid>
          <br />
        </>
      )}

      {testsByTopic?.length > 0 && renderCard()}
      {testsByTopic?.length === 0 && (
        <>
          <NoRecords message="no tests to display" />
          {props.canCreate && (
            <Grid
              className="text-center m-auto"
              item
              xs={6}
              lg={3}
            >
              <Button
                title={t("author.createTest", {})}
                onClick={createTest}
                className="btn-create-test m-auto"
                variant="contained"
                size="small"
              >
                <Add /> <Formatter options={{}} text="author.createTest" />
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
