import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Examsonline_Test_Sections } from "generated/graphql";

interface SectionBarProps {
  sections: Examsonline_Test_Sections[];
  totalQuestions: number;
}

function generateColor(index: number, total: number): string {
  const hue = 240;
  const saturation = 30;
  const lightness = 50 + (index / total) * 50;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

const useStyles = makeStyles((theme) => ({
  section: {
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textTransform: "capitalize",
  },
}));

const SectionBar: React.FC<SectionBarProps> = ({
  sections,
  totalQuestions,
}) => {
  const classes = useStyles();
  
  const sortedSections = [...(sections || [])].sort((a, b) =>
    a?.createdAt > b?.createdAt ? 1 : -1
  );
  if (sections?.length === 0) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      {sortedSections.map((section, index) => (
        <Box
          key={index}
          flexBasis={`${(section.questions.length / totalQuestions) * 100}%`}
          bgcolor={generateColor(index, sections.length)}
          className={classes.section}
        >
          {section.name} ({section.questions.length})
        </Box>
      ))}
    </Box>
  );
};

export default SectionBar;
