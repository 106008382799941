import Axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardContent,
} from "@material-ui/core";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Result } from "../../models/result.model";
import "./result.scss";
import { Examsonline_Tests } from "generated/graphql";

export interface Props {
  history: any;
  results: Result;
  test: Examsonline_Tests;
}

const COLORS = ["#14213d", "#00C49F", "#dec884"];

const ResultOverview: React.FC<Props> = ({ history, results, test }) => {
  interface PieData {
    name: string;
    value: number;
  }

  const [data, setData] = useState<PieData[]>([]);

  const download = (id: number) => {
    Axios.get(`result/download/${id}`, { responseType: "arraybuffer" }).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
      }
    );
  };

  useEffect(() => {
    setData([
      { name: "Wrong", value: results.attempt.metadata.incorrect },
      { name: "Correct", value: results.attempt.metadata.correct },
      { name: "Skipped", value: results.attempt.metadata.skipped },
    ]);
  }, [results]);

  const formatLegend = (str: string) => {
    if (str === "Correct") {
      return (
        <p>
          {" "}
          <FaCheck></FaCheck> {results.attempt.metadata.correct}
        </p>
      );
    }
    if (str === "Skipped") {
      return <p> {results.attempt.metadata.skipped} </p>;
    }
    if (str === "Wrong") {
      return (
        <p>
          {" "}
          <FaTimes></FaTimes> {results.attempt.metadata.incorrect}
        </p>
      );
    }

    return;
  };

  return (
    <Container maxWidth="md" className="mt-3" id="first">
      <Card>
        <CardContent className="p-1">
          <Grid container spacing={3} className="text-center font-weight-bold">
            <Grid item xs={12} md={6}>
              <TableContainer>
                <Table className="small" aria-label="summary table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Summary
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        className="lead text-mute"
                        align="center"
                      >
                        Test Name - <b>{test.name}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">Student</TableCell>
                      <TableCell align="center" className="text-primary">
                        {results.records[0]?.studentId}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        Percentage / Percentile
                      </TableCell>
                      <TableCell align="center" className="text-danger">
                        {results.attempt.metadata.percentage?.toFixed(2)} % /{" "}
                        {results.attempt.metadata.percentile?.toFixed(2)} %
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">Score</TableCell>
                      <TableCell align="center" className="text-danger">
                        {results.attempt.metadata.score} /
                        {results.attempt.metadata.total}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">Download</TableCell>
                      <TableCell align="center" className="text-danger">
                        <Button
                          size="small"
                          onClick={() => {
                            download(results.records[0].attemptId);
                          }}
                        >
                          .xlsx
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">Negative Marking</TableCell>
                      <TableCell align="center" className="text-danger">
                        {results.attempt?.metadata.hasNegativeMarking
                          ? "Yes"
                          : "No"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <ResponsiveContainer width="100%" height={270}>
                <PieChart>
                  <Pie
                    data={data}
                    innerRadius={50}
                    outerRadius={80}
                    fill="#106eea"
                    dataKey="value"
                    legendType="square"
                  >
                    {data.map((entry: any, index: any) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend formatter={(a) => <p>{formatLegend(a)}</p>} />
                </PieChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export { ResultOverview };
