import "./create-test.scss";
import "react-tagsinput/react-tagsinput.css";

import { useQuery } from "@apollo/client";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

import { Test } from "../../models/test.model";
import { GET_TEST_BY_ID } from "../../queries/queries";
import Loading from "../../shared/components/loader/loading";
import { ACCESS } from "../enums/access.enum";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import { Examsonline_Questions } from "generated/graphql";
import ErrorBanner from "component/banners/error";
import { useCreateTest } from "hooks/useCreateTest";
import { useUpdateTest } from "hooks/useUpdateTest";
import { TestForm } from "./testForm";
import { Grid } from "@material-ui/core";

export interface Props {
  testinfo: Test;
  questions: Examsonline_Questions[];
  post?: Function;
  history: any;
  updateTestInfo: Function;
  editTest: Function;
  match: any;
}

export const initialiseTestValue: Test = {
  name: "",
  pincode: "1234",
  showResult: true,
  enabled: true,
  time: 15,
  description: "",
  accesstype: ACCESS.PUBLIC,
  topics: [],
  author: "",
  questions: [],
  isDraft: false,
  errorMessage: "",
  randomise: true,
  emailnotify: true,
  maxAttempts: 3,
  test_sections: [],
};

export default function CreateTestForm(props: Props) {
  const [test, settest] = useState(initialiseTestValue);

  const { createTest } = useCreateTest();
  const { updateTest } = useUpdateTest();

  const nextbtnRef = useRef<any>();

  const { loading, error } = useQuery(GET_TEST_BY_ID, {
    variables: { id: parseInt(props.match.params.id) },
    skip: !props.match.params.id,
    onCompleted: (data) => {
      settest(data.examsonline_tests[0]);
    },
  });

  const { setInEditTestId } = useAppState();

  useEffect(() => {
    setInEditTestId(props?.match?.params?.id);
  }, []);

  const onBack = () => {
    props.history.push(`/`);
  };

  if (error && !!props.match.params.id) return <ErrorBanner />;

  const handleSubmit = async (test: any) => {
    let author: any = localStorage.getItem("token");
    if (!!author) {
      author = jwt_decode(author);
    }

    const temp = {
      ...test,
      topics: {
        data: test.topics
          .map((t: any) => (!!t?.tag ? t : { tag: t }))
          .map((t: any) => _.omit(t, "__typename")),
      },
      test_sections: {
        data: test?.test_sections?.length === 0 ? [{ name: "default" }] : [],
      },
      author: author.username,
      accesstype: Number(test.accesstype),
      maxAttempts: Number(test.maxAttempts),
      time: Number(test.time),
      points: Number(test.points) || 0 ,
    };
    delete temp.questions;
    delete temp.files;

    if (!!temp?.id && temp.hasOwnProperty("__typename")) {
      delete temp.__typename;
      delete temp.test_sections;
    }

    if (props.match.params.id) {
      const res = await updateTest(temp);

      props.history.push(
        `/edit/${res.update_examsonline_tests.returning[0].id}`
      );
    } else {
      const res = await createTest(temp);
      props.history.push(
        `/edit/${res.insert_examsonline_tests.returning[0].id}`
      );
    }
  };
  return (
    <Grid container spacing={3} className="p-4 " id="create-test-form">
      {loading && <Loading />}
      <Grid item xs={12}>
        <TestForm
          handleSubmit={handleSubmit}
          test={test}
          onBack={onBack}
          nextbtnRef={nextbtnRef}
        />
      </Grid>
    </Grid>
  );
}
