import { Container, Grid } from "@material-ui/core";
import AppStore from "./app-store";

export default function AppStoreContainer() {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <AppStore />
        </Grid>
      </Grid>
    </Container>
  );
}
