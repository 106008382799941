import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

interface UseGoogleAnalyticsOptions {
  options?: object;
}

const useGoogleAnalytics = (options?: UseGoogleAnalyticsOptions) => {
  const location = useLocation();

  const logPageChange = (pathname: string, search: string) => {
    const page = pathname + search;
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  useEffect(() => {
    logPageChange(location.pathname, location.search);
  }, [location.pathname, location.search]);
};

const init = (trackingId: string, options = {}) => {
  ReactGA.initialize(trackingId, options);
};

export { useGoogleAnalytics, init };
