import { useEffect, useRef, useState } from "react";
import { Examsonline_Questions } from "generated/graphql";
import { Answer } from "models/answer.model";

export const useReportTimesSpent = (
  activeQuestion: Examsonline_Questions | undefined | null,
  answers: Answer[],
  isInSubmitMode: boolean
) => {
  const [timeSpentMs, setTimeSpentMs] = useState<{ [key: string]: number }>({});
  const startTimeRef = useRef<number | null>(null);

  const finalizeTimeSpent = () => {
    if (startTimeRef.current && activeQuestion?.id) {
      const elapsedTime = Date.now() - startTimeRef.current;
      setTimeSpentMs((prev) => ({
        ...prev,
        [activeQuestion.id]: (prev[activeQuestion.id] || 0) + elapsedTime,
      }));
      startTimeRef.current = null;
    }
  };

  useEffect(() => {
    if (isInSubmitMode) {
      finalizeTimeSpent();
    }
  }, [isInSubmitMode]);

  useEffect(() => {
    if (!activeQuestion) return;

    // Record the start time for the active question
    startTimeRef.current = Date.now();

    return () => {
      finalizeTimeSpent();
    };
  }, [activeQuestion]);

  useEffect(() => {
    // Update answers with the current timeSpent data
    answers.forEach((answer) => {
      if (timeSpentMs[answer.questionId] !== undefined) {
        answer.timeSpent = timeSpentMs[answer.questionId];
      }
    });
  }, [timeSpentMs, answers]);

  return timeSpentMs;
};
