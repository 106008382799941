import {
  Button,
  Grid,
  LinearProgress,
  ThemeProvider,
  Typography,
  createTheme,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useTable } from "hooks/useTable";
import { useParams } from "react-router-dom";
import Loading from "shared/components/loader/loading";
import { useAnswerSheet } from "hooks/useAnswerSheet";
import { green } from "@material-ui/core/colors";
import SessionOverview from "component/session/sessionOverview";
import { useViewResultModal } from "hooks/useViewResultModal";
import { useGet_Session_By_IdQuery } from "generated/graphql";
import { DateComponent } from "component/date";

interface Props {
  sessionId?: string;
  email?: string;
}

const theme = createTheme({
  palette: {
    primary: green,
  },
});

const ProgressBarWithLabel = ({ value }: { value: number }) => {
  let color: "primary" | "secondary" = "primary";

  if (value < 40) {
    color = "secondary"; // Red color for values less than 40
  } else if (value >= 40 && value <= 60) {
    color = "primary"; // Blue color for values between 40 and 60
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ThemeProvider theme={theme}>
        <LinearProgress
          variant="determinate"
          color={color}
          value={value}
          style={{ flexGrow: 1 }}
        />
      </ThemeProvider>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ marginLeft: "10px", fontWeight: 600 }}
      >
        {`${Math.round(value)}%`}
      </Typography>
    </div>
  );
};

export default function SessionDetails(props: Props) {
  const [searchAttempt, setSearchAttempt] = useState("");
  const [, setTotal] = useState(10);

  const handleOnChange = (e: any) => {
    setSearchAttempt(e);
  };

  const params: { id: string } = useParams();

  const { data, loading, refetch } = useGet_Session_By_IdQuery({
    variables: {
      id: props?.sessionId || params.id,
    },
  });

  const { viewPage } = useViewResultModal("Result");

  const handleViewResult = (attempt) => {
    viewPage(
      `${import.meta.env.VITE_APP_URL}/result/${attempt.id}`,
      attempt.id
    );
  };

  useEffect(() => {
    data && setTotal(data.examsonline_session_by_pk?.attempts.length || 0);
  }, [data]);

  const attempts = data?.examsonline_session_by_pk?.attempts || [];

  const { AnswerSheetDrawer, AnswerSheetButton } = useAnswerSheet();

  const tableData = attempts
    ?.map((attempt) => {
      return {
        id: attempt.id,
        email: attempt.user,
        createdAt: (
          <DateComponent
            date={attempt.createdAt}
            shortDateFormat="MMMM Do YYYY, h:mm:ss a"
          />
        ),
        score: attempt.metadata.score,
        percentage: (
          <ProgressBarWithLabel value={attempt.metadata.percentage || 0} />
        ),
        rank: attempt.metadata.rank,
        percentile: attempt.metadata.percentile,
        actions: (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleViewResult(attempt)}
            color="primary"
          >
            View Result
          </Button>
        ),
        timeTaken: `${attempt.metadata.timeSpent / 1000}s`,
        answerSheet: (
          <AnswerSheetButton
            studentId={attempt.user || ""}
            attemptId={attempt.id}
          />
        ),
      };
    })
    .filter((attempt) => {
      return attempt.email?.includes(searchAttempt);
    });

  const table = useTable({
    data: tableData || [],
    filterColumns: ["__typename", "id"],
    pagination: true,
    handleSearch: handleOnChange,
    toolbar: true,
    heading: "Session Attempts",
  });

  const session = data?.examsonline_session_by_pk as any;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card style={{ minHeight: "500px" }}>
            <CardContent>
              <SessionOverview
                refetch={refetch}
                session={session}
                readonly={false}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Loading Indicator */}
        {loading && (
          <Grid item xs={12}>
            <Loading />
          </Grid>
        )}

        {/* Session Attempts Table */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box marginBottom={3}>{table}</Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AnswerSheetDrawer />
    </>
  );
}
