import React, { useState } from "react";
import { Examsonline_Session, Maybe } from "generated/graphql";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AllowListTable from "../session/allowListTable";
import NegativeMarking from "../session/negativeMarking";
import { useConfirmModal } from "hooks/useConfirmModal";
import { useAppContext } from "context/appProvider";
import { useTestById } from "shared/hooks/useTestById";

interface Props {
  testId: number | undefined;
  onSuccess: (id: string) => void;
  initialSession?: Maybe<Examsonline_Session>;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  sectionHeader: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  tabContent: {
    marginTop: theme.spacing(3),
  },
}));

export default function CreateTestSessionForm(props: Props): JSX.Element {
  const confirm = useConfirmModal();
  const context = useAppContext();
  const { test } = useTestById(props.testId);
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formik = useFormik({
    initialValues: {
      name: props.initialSession?.name || "",
      startTime: props.initialSession?.startTime || formatDate(new Date()),
      endTime: props.initialSession?.endTime || formatDate(tomorrow),
      allowList: props.initialSession?.allowList || [],
      negativemarking: props.initialSession?.negativemarking || 0,
      showResults: props.initialSession?.showResults ?? false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Session name is required"),
      startTime: Yup.date().required("Start date is required"),
      endTime: Yup.date().required("End date is required"),
      negativemarking: Yup.number()
        .min(0, "Negative marking must be at least 0")
        .max(1, "Negative marking can't be more than 1")
        .required("Negative marking is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await confirm(
        <Typography>
          Are you sure you want to {props.initialSession ? "update" : "create"}{" "}
          this session?
        </Typography>
      );

      const data = props.initialSession
        ? await context.sessionAPI.updateSession(
            props.initialSession.id,
            values
          )
        : await context.sessionAPI.create({
            ...values,
            test,
            sessionrecords: [],
          });

      resetForm();
      props.onSuccess((data as { session: { id: string } }).session.id);
    },
  });

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.sectionHeader}>
        {props.initialSession ? "Update Test Session" : "Create Test Session"}
      </Typography>
      <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary">
        <Tab label="Session Details" />
        <Tab label="Access Control" />
        <Tab label="Results Settings" />
      </Tabs>
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.tabContent}>
          {activeTab === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Session Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="startTime"
                  label="Start Time"
                  type="datetime-local"
                  value={formik.values.startTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.startTime && Boolean(formik.errors.startTime)
                  }
                  helperText={
                    formik.touched.startTime && formik.errors.startTime
                  }
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="endTime"
                  label="End Time"
                  type="datetime-local"
                  value={formik.values.endTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                  helperText={formik.touched.endTime && formik.errors.endTime}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          {activeTab === 1 && (
            <AllowListTable
              initialAllowList={formik.values.allowList}
              onAllowListChange={(val) =>
                formik.setFieldValue("allowList", val)
              }
            />
          )}
          {activeTab === 2 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <NegativeMarking
                  value={formik.values.negativemarking}
                  onChange={(value) =>
                    formik.setFieldValue("negativemarking", value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.showResults}
                      onChange={formik.handleChange}
                      name="showResults"
                    />
                  }
                  label="Show Results"
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <Box className={classes.buttonContainer}>
          <Button variant="outlined" onClick={formik.handleReset}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {props.initialSession ? "Update" : "Create"}
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
