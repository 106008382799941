import React, { useState } from 'react';
import { Grid, Paper, TextField, Button, MenuItem, Select, FormControl, InputLabel, Box } from '@material-ui/core';
import { QuestionDifficultyEnum } from 'component/enums/question.difficulty.enum';
import Question, { QuestionMode } from 'component/question/question';
import { Helper } from 'helper/helper';
import { useChatGPT } from 'hooks/useChatGpt';
import { useCopyQuestions } from 'shared/hooks/tests/useCopyQuestions';

function AskChatGpt() {
  const [keywords, setKeywords] = useState("");
  const { generateText, isLoading, error } = useChatGPT();
  const [generatedText, setGeneratedText] = useState({ questions: [] });
  const { copyAllQuestions } = useCopyQuestions();
  const [numQuestions, setNumQuestions] = useState(10);
  const [difficulty, setDifficulty] = useState(QuestionDifficultyEnum.EASY);

  const handleKeywordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(event.target.value);
  };

  const handleNumQuestionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumQuestions(parseInt(event.target.value, 10));
  };

  const handleDifficultyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDifficulty(event.target.value as QuestionDifficultyEnum);
  };

  const handleGenerateClick = async () => {
    const text = await generateText(keywords.split(","), numQuestions, difficulty);

    const questions = text?.map((question) => {
      return Helper.mapAiResponseToDraftJsQuestion(question);
    });

    setGeneratedText({ questions: questions ?? [] });
  };

  const handleCopyAllClick = () => {
    copyAllQuestions(generatedText.questions);
  };

  return (
    <Grid data-plan="pro" item className="\ advanced-settings" xs={12}>
      <Paper className="w-100 p-2 p-lg-4">
        <h4 className="p-1 font-krona">Ask AI to generate</h4>
        <TextField
          label="Keywords"
          variant="outlined"
          fullWidth
          value={keywords}
          onChange={handleKeywordsChange}
          margin="normal"
          disabled={false}
        />
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <TextField
              label="Number of Questions"
              type="number"
              variant="outlined"
              value={numQuestions}
              onChange={handleNumQuestionsChange}
              margin="normal"
              inputProps={{ min: 1 }}
            />
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120, marginTop: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="difficulty-select-label">Difficulty Level</InputLabel>
            <Select
              labelId="difficulty-select-label"
              id="difficulty-select"
              value={difficulty}
              label="Difficulty Level"
              onChange={handleDifficultyChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    zIndex: 1300, // Adjust the zIndex to ensure it appears above the modal
                  },
                },
              }}
            >
              {Object.values(QuestionDifficultyEnum).map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          disabled={false}
          variant="contained"
          onClick={handleGenerateClick}
          style={{ marginTop: '10px' }}
        >
          Generate
        </Button>
        <Button
          disabled={generatedText.questions.length === 0}
          variant="contained"
          onClick={handleCopyAllClick}
          style={{ marginLeft: '10px', marginTop: '10px' }}
        >
          Copy All Questions
        </Button>
        {isLoading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {generatedText?.questions &&
          generatedText?.questions?.map((question, index) => (
            <Question
            
              key={index}
              question={question}
              mode={QuestionMode.copy}
              uploadImageCallBack={undefined}
            />
          ))}
      </Paper>
    </Grid>
  );
}

export default AskChatGpt;