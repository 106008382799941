import { Examsonline_Comments } from "generated/graphql";
import { Grid, Typography, Avatar, Chip } from "@material-ui/core";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  comment: Examsonline_Comments;
}

const useStyles = makeStyles((theme) => ({
  commentContainer: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold", // keeps boldness in the Avatar
    width: 40,
    height: 40,
  },
  commentContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  commentText: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.5,
    color: theme.palette.text.primary,
  },
  chip: {
    fontSize: "0.875rem",
    fontWeight: 500, // numeric 500 for fontWeight
    textTransform: "capitalize",
  },
  timestamp: {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
}));

const handleColor = (role: string) => {
  switch (role) {
    case "support":
      return "secondary";
    case "author":
      return "primary";
    default:
      return "default";
  }
};

export default function ViewComment(props: Props) {
  const { comment } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.commentContainer}
      alignItems="flex-start"
    >
      <Grid item xs={2} sm={1}>
        <Avatar className={classes.avatar}>
          {comment.user[0].toUpperCase()}
        </Avatar>
      </Grid>
      <Grid item xs={10} sm={11} className={classes.commentContent}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body2" className={classes.timestamp}>
              <Moment fromNow>{comment.updatedAt}</Moment>
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label={comment.role}
              color={handleColor(comment.role || "default")}
              className={classes.chip}
            />
          </Grid>
        </Grid>

        <Typography variant="body1" className={classes.commentText}>
          {comment.value}
        </Typography>
      </Grid>
    </Grid>
  );
}
