import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { useModalContext } from "context/modalProvider";
import { useCallback, useState } from "react";
import QRCode from "qrcode.react";
import { Comments } from "shared/components/comments/comments";
import { useUpdate_BillMutation, Get_BillsDocument } from "generated/graphql";

export const usePaymentModal = () => {
  const { openModal } = useModalContext();
  const [updateBill] = useUpdate_BillMutation();
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = useCallback(
    (billing_id) => {
      const handleUpdateBill = async () => {
        setIsLoading(true);
        try {
          await updateBill({
            variables: {
              id: { _eq: billing_id },
              status: "INREVIEW",
            },
            refetchQueries: [Get_BillsDocument],
          });
          alert('Payment status updated!');
        } catch (error) {
          console.error('Error updating payment status:', error);
          alert('Failed to update payment status.');
        } finally {
          setIsLoading(false);
        }
      };

      const footer = (id) => (
        <Tooltip title="Click 'I have paid' once you upload proof of payment">
          <Button
            onClick={handleUpdateBill}
            variant="contained"
            color="secondary"
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'I have paid'}
          </Button>
        </Tooltip>
      );

      const body = (
        <Grid container spacing={3} className="d-flex">
          <Grid item xs={12} className="d-flex">
            <Typography variant="h6" className="m-auto">Payment Details</Typography>
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-center">
            <Typography>Please scan the QR code below to make a payment:</Typography>
          </Grid>
          <Grid item xs={12} className="d-flex justify-content-center">
            <QRCode value="Payment URL" />
          </Grid>
          <Grid item xs={12}>
            <Comments
              placeholder="Please provide proof of your payment"
              ref_id={billing_id}
            />
          </Grid>
        </Grid>
      );

      openModal("Make a Payment", body, footer(billing_id), "md");
    },
    [openModal, updateBill, isLoading]
  );

  return handleOpenModal;
};
