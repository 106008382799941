import React, { useState } from "react";
import {
  Card,
  CardContent,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
} from "recharts";
import moment from "moment";
import { Share, Stop, Refresh } from "@material-ui/icons";
import { useShareTest } from "hooks/useShare";
import useSessionStatus from "hooks/useSessionStatus";
import axios from "axios";
import { useToast } from "shared/hooks/useToast";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  content: {
    padding: theme.spacing(2),
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabs: {
    marginTop: theme.spacing(2),
  },
  chartContainer: {
    height: 300,
    marginTop: theme.spacing(3),
  },
}));

const getBarColor = (score, theme) => {
  if (score < 50) return theme.palette.error.main; // red
  if (score >= 50 && score < 70) return theme.palette.warning.main; // yellow
  return theme.palette.success.main; // green
};

const SessionOverviewCard = ({ session, readonly, refetch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleOpen } = useShareTest();
  const [activeTab, setActiveTab] = useState(0);

  const { successToast, dangerToast } = useToast();

  if (!session) return null;

  const formattedStartTime = moment(session.startTime).format("LLL");
  const formattedEndTime = moment(session.endTime).format("LLL");
  const status = useSessionStatus(session);

  const percentilesData = Object.keys(session.metadata?.percentiles || {}).map(
    (key) => ({
      percentile: key,
      value: session.metadata.percentiles[key],
    })
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const scores = session.attempts.map((attempt) => ({
    date: attempt.createdAt,
    score: attempt.metadata.score || 0,
    studentId: attempt.user,
    percentage: attempt.metadata.percentage || 0,
  }));

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p className="label">{`Date: ${moment(label).format("LLL")}`}</p>
          <p className="intro">{`Score: ${payload[0].value}`}</p>
          <p className="desc">{`User: ${payload[0].payload.studentId}`}</p>
        </div>
      );
    }

    return null;
  };

  const handleEndSession = () => {
    axios
      .post(
        `${import.meta.env.VITE_REACT_APP_API_URL}/session/${session.id}/end`
      )
      .then(() => {
        successToast("Session ended successfully");
        refetch();
      })
      .catch(() => {
        dangerToast("Failed to end session");
      });
  };

  const handlePauseSession = () => {
    axios
      .post(
        `${import.meta.env.VITE_REACT_APP_API_URL}/session/${session.id}/pause`
      )
      .then(() => {
        successToast(session.pause ? "Session resumed" : "Session paused");
        refetch();
      })
      .catch(() => {
        dangerToast("Failed to pause session");
      });
  };

  const handleRefreshMetadata = () => {
    axios
      .post(
        `${import.meta.env.VITE_REACT_APP_API_URL}/session/${session.id}/refresh-metadata`
      )
      .then(() => {
        successToast("Metadata refreshed successfully");
        refetch();
      })
      .catch(() => {
        dangerToast("Failed to refresh metadata");
      });
  };

  return (
    <Grid container direction={isSmallScreen ? "column" : "row"}>
      <Card elevation={0} className={classes.root}>
        <CardContent className={classes.content}>
          <Typography
            variant="h6"
            gutterBottom
            className="d-flex justify-content-between align-items-center"
          >
            Session Overview
            <Box marginTop={2}>
              <Button
                disabled={session?.pause || session?.completed || readonly}
                variant="contained"
                color="secondary"
                startIcon={<Stop />}
                onClick={handleEndSession}
              >
                {session?.completed ? "Session Ended" : "End Session"}
              </Button>
            </Box>
            <Box marginTop={2} hidden={session?.completed}>
              <Button
                variant="contained"
                color={session.pause ? "primary" : "default"}
                onClick={handlePauseSession}
              >
                {session.pause ? "Resume Session" : "Pause Session"}
              </Button>
            </Box>
            <Box marginTop={2}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Refresh />}
                onClick={handleRefreshMetadata}
              >
                Refresh Metadata
              </Button>
            </Box>
          </Typography>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
            variant={isSmallScreen ? "scrollable" : "standard"}
            scrollButtons={isSmallScreen ? "on" : "off"}
          >
            <Tab label="Summary" />
            <Tab label="Score Trends" />
            <Tab label="Percentiles" />
          </Tabs>

          {/* Summary Tab */}
          <Box hidden={activeTab !== 0}>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemText primary="Session Name" />
                <ListItemSecondaryAction>
                  <Typography variant="body1">{session.name}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary="Start Time" />
                <ListItemSecondaryAction>
                  <Typography variant="body1">{formattedStartTime}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary="End Time" />
                <ListItemSecondaryAction>
                  <Typography variant="body1">{formattedEndTime}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary="Status" />
                <ListItemSecondaryAction>
                  <Typography variant="body1">{status().label}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary="Average Score" />
                <ListItemSecondaryAction>
                  <Typography variant="body1">
                    {session.metadata?.averageScore || "N/A"}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary="Share Session" />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => handleOpen(`/session/login/${session.id}`)}
                  >
                    <Share />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary="Session ID" />
                <ListItemSecondaryAction>
                  <Typography variant="body1">{session.id}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary="Allow List" />
                <ListItemSecondaryAction>
                  <Typography variant="body1">
                    {session.allowList.length > 0
                      ? session.allowList.map((email) => (
                          <span key={email}>{email} </span>
                        ))
                      : "Open for all"}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Box>

          {/* Score Trends Tab */}
          <Box hidden={activeTab !== 1} className={classes.chartContainer}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={scores}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  label="Date"
                  dataKey="date"
                  tickFormatter={(date) => moment(date).format("MMM D")}
                />
                <YAxis label="scores" />
                <Tooltip content={<CustomTooltip />} />
                <Tooltip
                  labelFormatter={(date) => moment(date).format("LLL")}
                />
                <Bar dataKey="score" fill="#8884d8">
                  {scores.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getBarColor(entry.percentage, theme)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>

          {/* Percentiles Tab */}
          <Box hidden={activeTab !== 2} className={classes.chartContainer}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={percentilesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="percentile" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SessionOverviewCard;
