import { Formik } from "formik";
import { Component } from "react";
import { Button, Card, CardContent, TextField, Grid, Box, Typography } from "@material-ui/core";
import * as yup from "yup";

export default class StudentResultSearch extends Component<any> {
  checkTestId = (form: any) => {
    this.props.history.push(`/findresults/${form.email}`);
  };

  schema1 = yup.object({
    email: yup
      .string()
      .required("No email or Roll No provided.")
      .min(3)
      .matches(/^[a-zA-Z0-9_.@-]+$/, "Invalid Email or Roll No"),
  });

  render() {
    return (
      <Box>
        <Grid container justify="center">
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Formik
                  validationSchema={this.schema1}
                  onSubmit={this.checkTestId}
                  initialValues={{ email: "" }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <form id="right-box" noValidate onSubmit={handleSubmit}>
                      <Typography variant="h5" className="testTitle p-3" align="center">
                        Find Results
                      </Typography>
                      <TextField
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        placeholder="Enter your email or Roll no"
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                      />
                      <Box textAlign="center" pt={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Search
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
