import { Grid, Paper, Box, useTheme, Typography } from "@material-ui/core";
import { IsCorrect } from "component/question/edit/iscorrect";
import draftToHtml from "draftjs-to-html";
import { Option } from "models/option.model";
import "./options-readonly.css"; // Updated CSS file

interface Props {
  options: Option[];
  selectedOptions: number[];
  textAnswer?: string | null;
}

export function OptionsReadonly({
  options,
  selectedOptions: selected,
  textAnswer,
}: Props) {
  const theme = useTheme();

  const isCorrect = (option: Option) => {
    const isSelected = selected.includes(option.id);

    if (
      !isSelected ||
      (selected.length < options.filter((o) => o.isCorrect).length &&
        selected.length > 0)
    )
      return "skipped";

    if (isSelected && option.isCorrect) return true;

    if (isSelected && !option.isCorrect) return false;

    return null;
  };

  const getBackgroundColor = (option: Option) => {
    if (selected?.includes(option.id)) {
      if (isCorrect(option) === "skipped") {
        return theme.palette.warning.light;
      }
      return isCorrect(option)
        ? theme.palette.success.light
        : theme.palette.error.light;
    }
    return theme.palette.background.paper;
  };

  const getTextColor = () => {
    return theme.palette.text.primary;
  };

  const convertToHtml = (value: string) => {
    try {
      const parsedValue = JSON.parse(value);
      if (parsedValue.blocks && parsedValue.entityMap) {
        return draftToHtml(parsedValue);
      }
    } catch (e) {
      console.error("Error parsing draft HTML block:", e);
    }
    return value;
  };

  if (!options) return null;

  if (textAnswer && options.length === 0) {
    return (
      <Grid container justifyContent="center" className="text-answer-container">
        <Grid item xs={12} sm={8} md={6}>
          <Paper className="option-container" elevation={3}>
            <Box p={2}>
              <Typography >
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: convertToHtml(textAnswer),
                  }}
                />
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className="options-container">
      {options.map((option, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Paper
            className="option-container"
            elevation={3}
            style={{
              backgroundColor: getBackgroundColor(option),
              borderRadius: theme.shape.borderRadius,
              color: getTextColor(),
            }}
          >
            <Grid container alignItems="center">
              <Grid
                item
                xs={3}
                sm={2}
                className="is-correct-container"
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: theme.spacing(1),
                  minWidth: "50px", // Updated width
                }}
              >
                <IsCorrect readonly={true} option={option} />
              </Grid>
              <Grid
                item
                xs={9}
                sm={10}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: getTextColor(),
                }}
              >
                <Box p={2}>
                    <Typography
                    variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: convertToHtml(option.value),
                      }}
                    />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
