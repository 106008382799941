import { useTheme, useMediaQuery } from "@material-ui/core";
import { ContextBadge } from "./contextBadge";

interface StatusProps {
  status: string;
}

const Status: React.FC<StatusProps> = ({ status }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let variant: "success" | "warning" | "danger" | "info" | "default";

  switch (status) {
    case "Active":
      variant = "success";
      break;
    case "Finished":
      variant = "danger";
      break;
    case "Paused":
      variant = "warning";
      break;
    default:
      variant = "info";
  }

  return (
    <ContextBadge
      variant={variant}
      className={isSmallScreen ? 'small-screen' : 'large-screen'}
    >
      {status}
    </ContextBadge>
  );
};

export default Status;
