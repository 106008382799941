import moment from "moment";
import Status from "component/status";
import { Examsonline_Session } from "generated/graphql";

const useSessionStatus = (session: Examsonline_Session) => {
  if (!session) {
    return () => ({ status: "Expired", label: <Status status="Finished" /> });
  }

  const { startTime, endTime, completed, pause } = session;

  return () => {
    const now = moment();
    const start = moment(startTime);
    const end = moment(endTime);

    if (completed) {
      return { status: "Finished", label: <Status status="Finished" /> };
    }

    if (pause) {
      return { status: "Paused", label: <Status status="Paused" /> };
    }

    if (now.isBetween(start, end)) {
      return { status: "Active", label: <Status status="Active" /> };
    }

    if (now.isBefore(start)) {
      return { status: "Future", label: <Status status="Active" /> };
    }

    return { status: "Expired", label: <Status status="Finished" /> };
  };
};

export default useSessionStatus;
