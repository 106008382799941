import { useState } from "react";
import usePagintaion from "shared/hooks/pagination/usePagintaion";
import { Grid, TextField } from "@material-ui/core";
import Loading from "shared/components/loader/loading";

import NoRecords from "component/banners/no-records";
import { Examsonline_Questions } from "generated/graphql";
import { useQuery } from "@apollo/client";
import { SEARCH_QUESTIONS } from "queries/queries";
import Question, { QuestionMode } from "component/question/question";

interface Props {
  contest?: string;
  enableSelect?: boolean;
  onSelect?: (test: Examsonline_Questions) => void;
}
export default function QuestionGrid(props: Props) {
  const [limit] = useState(10);
  const [questions, setquestions] = useState<Examsonline_Questions[]>([]);
  const [totalcount, settotalcount] = useState<number | undefined>(0);

  const [search, setsearch] = useState("");
  const handleOnChange = (e: any) => {
    setsearch(e.target.value);
    reset();
  };

  const { pages, activePage, reset } = usePagintaion(totalcount || 0, limit);

  const { loading } = useQuery(SEARCH_QUESTIONS, {
    variables: {
      _iregex: search,
      _iregex1: search,
      limit: limit,
      offset: limit * (activePage - 1),
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setquestions(data.examsonline_questions);
      settotalcount(data.total_count?.aggregate?.count);
    },
  });

  return (
    <Grid container spacing={2}>
      {loading && <Loading />}
      <Grid item xs={12} lg={4}>
        <TextField
          fullWidth
          variant="outlined"
          onChange={handleOnChange}
          value={search}
          type="text"
          placeholder="Search"
          size="small"
          className="m-auto \"
          data-testid="srch-question-input"
        />
      </Grid>
      <Grid item xs={12} lg={5} className="m-auto">
        {pages()}
      </Grid>
      <Grid item xs={12}>
        {questions &&
          questions.map((a: any) => {
            return (
              <Question
                key={a.id}
                question={a}
                mode={QuestionMode.copy}
                uploadImageCallBack={undefined}
              />
            );
          })}
        {questions.length === 0 && <NoRecords />}
      </Grid>
    </Grid>
  );
}
