import {
  CardContent,
  Card,
  CardActionArea,
  CardActions,
  IconButton,
  CardMedia,
  Button,
  Badge,
} from "@material-ui/core";
import { Group } from "@material-ui/icons";
import { Examsonline_Groups } from "generated/graphql";
import { Helper } from "helper/helper";
import { Roles } from "models/roles";
import { useHistory } from "react-router";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import "./action-card.scss";

interface Props {
  group: Examsonline_Groups;
}

export default function GroupCardSquare(props: Props) {
  const history = useHistory();
  const role = Helper.getUserRole();
  const isloggedIn = !!localStorage.getItem("token") ? true : false;

  const { showContentPickerModal, setEditGroup } = useAppState();

  const openModal = () => {
    setEditGroup(props.group.id);
    showContentPickerModal(true);
  };

  const gotoGroup = () => {
    history.push(`/student/group/${props.group.id}`);
  };

  return (
    <Card className="border border-dark group-card-square">
      <CardActionArea onClick={gotoGroup}>
        <CardContent
          data-testid="group-card-image"
          className="text-center"
          style={{ height: "130px" }}
        >
          <CardMedia
            className="rounded bg-blur"
            style={{ width: "100%", height: "100%" }}
            image={props.group.files[0]?.info?.location || "/group.svg"}
            title={props.group.name}
          />
          <div className="bg-info-text">
            <Badge color="primary">{props?.group?.name}</Badge>
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions className="justify-content-between">
        <IconButton aria-label="delete">
          <Group /> {props.group.members_aggregate.aggregate?.count}
        </IconButton>
        <span>
          <Badge color="secondary">
            {props.group.assignments_aggregate.aggregate?.count} items
          </Badge>
        </span>
        <Button
          hidden={!isloggedIn || role !== Roles.AUTHOR}
          color="primary"
          className="py-0 mx-1"
          size="small"
          onClick={openModal}
        >
          Assign
        </Button>
        <Button
          hidden={!isloggedIn || role !== Roles.STUDENT}
          color="primary"
          className="py-0 mx-1"
          size="small"
        >
          Join
        </Button>
        <Button
          hidden={isloggedIn || props.group.public}
          color="primary"
          className="py-0 mx-1"
          size="small"
        >
          View
        </Button>
        <Button
          hidden={isloggedIn || !props.group.public}
          color="primary"
          className="py-0 mx-1"
          size="small"
        >
          login
        </Button>
      </CardActions>
    </Card>
  );
}
