import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  onclick: any;
}

const useStyles = makeStyles({
  image: {
    width: "100%",
    height: "auto",
  },
});

export function NetworkError(props: Props) {
  const classes = useStyles();

  const handleClick = () => {
    props.onclick();
  };

  return (
    <Container>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <img src={"images/network-error.jpg"} alt="Network Error" className={classes.image} />
        </Grid>
        <Grid item>
          <Button onClick={handleClick} variant="outlined" color="primary">
            Retry
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
