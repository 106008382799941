import { useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import CreateTestSessionForm from "component/forms/createTestSessionForm";
import { Grid } from "@material-ui/core";
import { useModalContext } from "context/modalProvider";
import { useShareTest } from "./useShare";

export const useCreateSession = (testId?: number) => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const { openModal } = useModalContext();
  const { handleOpen } = useShareTest()


  const create = async (sessionId: string) => {
    setSessionId(sessionId);
    handleOpen(`/session/login/${sessionId}`);
  };

  const openCreateSessionModal = useCallback(() => {
    if (testId) {
      openModal(
        "Create Session",
        <>  <CreateTestSessionForm testId={testId} onSuccess={create} /> </>,
        null,
        "md"
      );
    }
  }, [testId, openModal, create]);

  const ModalWithForm = () => (
    <Grid container spacing={2}>
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={openCreateSessionModal}
      >
        Create Session
      </Button>
    </Grid>
  );

  return {
    ModalWithForm, sessionId, createsession: openCreateSessionModal
  };
};