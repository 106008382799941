import { Formik } from "formik";
import TagsInput from "react-tagsinput";
import { testValidationSchema } from "../create-test/helper";

import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
} from "@material-ui/core";

export const TestForm = ({ handleSubmit, onBack, test, nextbtnRef }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={testValidationSchema}
      onSubmit={handleSubmit}
      initialValues={{ ...test, accesstype: test?.accesstype.toString() }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => (
        <Paper elevation={3} style={{ padding: "2rem", margin: "2rem auto", maxWidth: 800 }}>
          <Typography variant="h5" gutterBottom align="center">
            Test Details
          </Typography>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Test Name"
                  name="name"
                  placeholder="Enter test name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.name && errors.name ? errors.name : "Looks good!"}
                  error={Boolean(touched.name && errors.name)}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Topics related to this test
                </Typography>
                <TagsInput
                  inputProps={{
                    placeholder: "e.g., maths, science",
                  }}
                  value={values?.topics.map((t) =>
                    typeof t === "string" ? t : t?.tag
                  )}
                  onChange={(e) =>
                    setFieldValue(
                      "topics",
                      e.map((sr) => sr.toLowerCase())
                    )
                  }
                  addOnBlur={true}
                />
                {touched.topics && errors.topics && (
                  <Typography color="error" variant="body2">
                    {errors.topics}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Test Description"
                  name="description"
                  placeholder="A few words about this test"
                  multiline
                  rows={4}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.description && errors.description
                      ? errors.description
                      : "Provide a concise description."
                  }
                  error={Boolean(touched.description && errors.description)}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Time (in minutes)"
                  name="time"
                  type="number"
                  value={values.time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.time && errors.time ? errors.time : "Set a time limit for the test."
                  }
                  error={Boolean(touched.time && errors.time)}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onBack}
                    size="large"
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    ref={nextbtnRef}
                    size="large"
                  >
                    Next
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </Formik>
  );
};
