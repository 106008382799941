import "./sidebar.scss";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useAppState } from "shared/hooks/useUpdateAppstate";
import { SideBarMenuList } from "./menuList";
import { useAuth } from "context/authProvider";
import { Hidden } from "@material-ui/core";

export const SideBar = () => {
  const { isLoggedIn } = useAuth();
  const history = useHistory();
  const theme = useTheme();

  const gotoProfile = () => {
    history.push("/profile");
  };

  const { state } = useAppState();

  if (!isLoggedIn || !state.showSidebar) {
    return <></>;
  }

  return (
    <Hidden smDown>
      <Grid container direction="column" className="sidebar-content">
        <nav className=" sidebar">
          <Card
            elevation={0}
            className="plan-card d-none d-md-inline text-center pointer  "
          >
            <CardContent>
              <Typography
                onClick={gotoProfile}
                variant="h6"
                color="textSecondary"
                gutterBottom
              >
                {state.userInfo?.username}
              </Typography>
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.light,
                }}
                className="upgrade-button"
              >
                Free Plan
              </Button>
            </CardContent>
          </Card>

          <SideBarMenuList />
        </nav>
      </Grid>
    </Hidden>
  );
};
