import { Typography, Grid, TextField } from "@material-ui/core";
import { Examsonline_Tests_Order_By } from "generated/graphql";
import { useTheme } from "@material-ui/core/styles";

interface TableToolbarProps {
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSortChange?: (event: Examsonline_Tests_Order_By) => void;
  searchValue: string;
  title: React.ReactNode;
  placeholder: string;
}

const noop = () => {};

const TableToolbar = ({
  handleOnChange = noop,
  handleSortChange = noop,
  searchValue,
  title,
  placeholder,
}: TableToolbarProps) => {
  const theme = useTheme();
  const isDarkTheme = theme.palette.type === "dark";

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={5}
    >
      <Grid item xs={12} sm={4} className="m-0 m-lg-2">
        <Typography variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          style={{}}
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          onChange={handleOnChange}
          value={searchValue}
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default TableToolbar;
