import { useModalContext } from "context/modalProvider";
import { TestMetadata } from "../testMetdata";

export default function useTestAnalytics() {
  const { openModal } = useModalContext();

  const showModal = (testId: string) => {
    openModal("Test Analytics", <TestMetadata testId={testId} />, null, "md");
  };

  return { showModal };
}
