import { CircularProgress } from '@material-ui/core';
import './loader.scss'

export default function Loading() {
  return (
    <div
      id="loader"
      className="modal-backdrop show"
    >
      <CircularProgress
        className="logo"
        color="primary"
        role="status"
      />
    </div>
  );
}