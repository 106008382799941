import { useMutation } from "@apollo/client";
import { convertToRaw } from "draft-js";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";

import { Option } from "../../../models/option.model";
import { UPDATE_OPTION_BY_ID } from "../../../queries/queries";
import { Button, CircularProgress, Grid, useTheme } from "@material-ui/core";

interface Props {
  option: Option;
  readonly?: boolean;
}

/** functional component  */
export function IsCorrect(props: Props) {
  const theme = useTheme(); // use Material-UI theme
  const [addoption, { loading }] = useMutation(UPDATE_OPTION_BY_ID);
  const toggle = () => {
    // only call when readonly
    if (!props.readonly) {
      addoption({
        variables: {
          _set: {
            ...props.option,
            isCorrect: !props.option.isCorrect,
            value: JSON.stringify(
              convertToRaw(props.option.value.getCurrentContent())
            ),
          },
          id: props.option.id,
        },
      });
    }
  };

  const display = (isCorrect: boolean) => {
    const buttonClass = isCorrect ? "btn-wrong" : "btn-correct";
    const themeClass = theme.palette.type === 'dark' ? "dark-theme" : "light-theme"; // determine theme class

    if (isCorrect) {
      return (
        <Grid container justifyContent="flex-start">
          <Grid item>
            <Button
              title="This option is set as correct"
              className={`${buttonClass} ${themeClass} m-1`} // apply theme class
              disabled={props?.readonly}
              variant="contained"
              size="large"
            >
              <>
                {loading && <CircularProgress size="small" />}
                <FaCheckSquare></FaCheckSquare>
              </>
            </Button>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container justifyContent="flex-start">
        <Button
          title="This option is set as incorrect"
          className={`${buttonClass} ${themeClass} m-1`} // apply theme class
          disabled={props?.readonly}
          variant="contained"
          size="large"
        >
          <>
            {loading && <CircularProgress size="small" />}
            <FaRegSquare></FaRegSquare>
          </>
        </Button>
      </Grid>
    );
  };

  return (
    <div key={props?.option?.id} className="iscorrect" onClick={toggle}>
      {display(props.option.isCorrect)}
    </div>
  );
}
